.profile-head-container a {
  text-decoration: none;
  color: #000;
}

.contactbook-icon {
  margin-right: 5px;
}

.icons {
  margin-right: 4px;
}

.profile-header {
  display: flex;
  align-items: center;
}

.profile-head-container nav ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.profile-head-container nav li {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-right: 30px;
  transition: all 0.2s linear;
}

.active {
  border-bottom: 2px solid #00838f;
}

.profile-head-containernav ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}

.profile-head-containernav ul li a:hover,
.profile-head-containernav ul li a:focus {
  text-decoration: none;
  color: #000;
}

@media all and (min-width: 768px) {
  .profile-head-container nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
  }
}
