.notification-search-icon {
  margin-top: 6px;
}

.notification-container {
  color: #999;
  width: 320px;
  margin: 0 auto;
  height: 200vh;
  border: 1px solid #ccc;
  background-color: #e8eff9;
  transition: all 0.3s ease-in;
  scroll-behavior: smooth;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}

.notification-container h4 {
  color: #000;
}

.notification-container.active {
  top: 0;
  right: 0;
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(260deg, #46b3ff 0, #0752fe 100%);
  color: #fff;
  padding: 20px 30px;
  position: sticky;
  top: 0;
  right: 0;
  width: 320px;
  z-index: 1;
}

.notification-header .fa-search {
  margin-right: 16px;
  cursor: pointer;
}

.notification-header .fa-times {
  cursor: pointer;
}

.notification-body-header {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.notification-body-header button {
  width: 100%;
  box-sizing: border-box;
  padding: 14px 0px;
  margin: 20px 0px;
  font-size: 16px;
  background: #f6f9fc;
  color: #7d9ab3;
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 0 0 2 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.notification-body {
  padding: 0 10px 30px;
  position: relative;
  overflow-y: auto;
}

.notification-body-content {
  height: 100%;
  color: #000;
  background-color: #fff;
  padding: 20px 20px 30px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.notification-body-content-img img {
  width: 100%;
  margin: 10px 0px 30px;
}

.notification-input {
  padding: 2px;
  background-color: #fff;
  margin: 20px 10px 0px;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 18px;
  display: none;
  transition: all 0.45s ease-in;
  border: 1px solid #ccc;
}

.notification-input input {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
}

.notification-input .fa-search {
  color: #999;
  margin-right: 10px;
  cursor: pointer;
}

.notification-input.active {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media all and (min-width: 768px) {
  .notification-container {
    width: 500px;
  }

  .notification-header {
    padding: 22px 30px;
    width: 100%;
  }

  .notification-input.active {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
