.payment-modal-container {
  background: #fff;
  width: 95%;
  margin: 40px auto;
  border: none;
  outline: none;
  top: 100px;
}

.modal-header {
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  padding: 10px;
  color: #fff;
  font-weight: 600;
  position: relative;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  text-align: center;
}
.modal-header-signup {
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  padding: 10px;
  color: #fff;
  font-weight: 600;
  position: relative;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  text-align: center;
}
.modal-header .close {
  position: absolute;
  top: 7px;
  right: 15px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.6rem;
  cursor: pointer;
}

.modal-header-signup .close {
  position: absolute;
  top: 7px;
  right: 15px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.6rem;
  cursor: pointer;
}

.modal-body-title {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.modal-header p {
  margin-bottom: 0 !important;
  font-size: 1.1rem;
}
.securePayment img {
  margin: 0 auto;
  /* width: 425px; */
  display: flex;
  /* height: 122px; */
  width: 80%;
  object-fit: cover;
}
.paymentFrm {
  padding: 0px 10px;
}

.paymentFrm .form-group label {
  display: block;
  margin-bottom: 8px;
}

.paymentFrm .form-group input {
  display: block;
  width: 100%;
  margin-bottom: 12px;
  padding: 8px 20px;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 10px -4px #ccc;
}

.form-control.red-error-border {
  border: none !important;
  border: 1px solid #ff6046 !important;
}

.form-group-container {
  display: flex;
  justify-content: space-between;
}

.col-xs-4 {
  width: 32%;
}

.payment-success-button {
  width: 100%;
  display: inline-block;
  padding: 12px;
  margin-top: 20px;
  font-size: 15px;
  text-align: center;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(7deg, #0752feeb 0, #46b3ff 100%);
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s linear;
}

.payment-success-button-signup {
  margin-bottom: 0px;
}

.payment-success-button:hover,
.payment-success-button:focus {
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
}

.payment-success-button.disabled:hover {
  cursor: not-allowed;
}

.payment-success-button.disabled {
  cursor: progress;
  pointer-events: all !important;
}

.payment-footer-signup {
  background: linear-gradient(7deg, #46b3ff 0, #0752feeb 100%);
  text-align: center;
  padding: 20px 0px;
  margin-top: 15px;
  position: relative;
  width: 100%;
  bottom: 0;
  color: #fff;
}

.payment {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 20px;
}

.payment .lock-icon {
  width: 18px;
  height: 18px;
  margin-right: 3px;
  /* margin-bottom: 8px; */
}

.payment span {
  font-size: 24px;
  color: #fff;
  font-family: lato;
  font-weight: 600;
}

.modal-body-title-left h4 {
  font-size: 1rem;
}

.modal-body-title-right {
  font-size: 24px;
  color: #f3881f;
  font-weight: 700;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem 0 1rem !important;
}
.cupon-click {
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: var(--orange);
}

.cupon-validate-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cupon-validate-content {
  width: 100% !important;
}
.cupon-validate-container input {
  width: 90% !important;
}

.cupon-validate-button {
  padding: 8px 20px;
  background-color: var(--orange);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s linear;
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
}

.cupon-validate-button:hover,
.cupon-validate-button:focus {
  letter-spacing: 1px;
}

.account-footer {
  text-align: center;
  margin-top: 20px;
  font-size: 1.1rem;
}

.account-footer span {
  cursor: pointer;
  text-decoration: underline;
}

.thanku-content {
  text-align: center;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.thanku-content h4 {
  font-size: 1.8rem;
  color: rgb(42, 197, 42);
}

.thanku-content p {
  color: rgb(253, 10, 10);
  text-transform: capitalize;
}

@media all and (min-width: 768px) {
  .payment-modal-container {
    width: 40%;
  }

  .modal-body-title-left h4 {
    font-size: 1.5rem;
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 4rem 1rem 2rem !important;
  }

  .modal-header {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .modal-header-signup {
    background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
    padding: 10px;
    color: #fff;
    font-weight: 600;
    position: relative;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    text-align: center;
  }

  .modal-body-title {
    display: flex;
    padding: 10px 30px;
    justify-content: space-between;
  }
  .paymentFrm {
    padding: 0px 30px;
  }

  .cupon-click {
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    color: var(--orange);
  }

  .cupon-validate-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .cupon-validate-content {
    width: 100% !important;
  }
  .cupon-validate-container input {
    width: 90% !important;
  }

  .cupon-validate-button {
    padding: 8px 20px;
    background-color: var(--orange);
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s linear;
    margin-top: 0px;
  }

  .cupon-validate-button:hover,
  .cupon-validate-button:focus {
    letter-spacing: 1px;
  }

  .payment-success-button {
    margin-bottom: 40px;
  }

  .payment-success-button-signup {
    margin-bottom: 0px;
  }

  .payment-footer {
    width: 100%;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
  }
  .payment-footer-signup {
    background: linear-gradient(7deg, #46b3ff 0, #0752feeb 100%);
    text-align: center;
    padding: 20px 0px;
    margin-top: 15px;
    position: relative;
    width: 100%;
    bottom: 0;
    color: #fff;
  }
}

@media all and (min-width: 768px) and (max-width: 1000px) {
  .payment-modal-container {
    width: 55%;
  }
  .cupon-validate-container input {
    width: 90% !important;
  }
}
