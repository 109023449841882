.single-domain-container {
  padding: 170px 10px 40px;
  color: #241c15;
}

.youtube-icon {
  margin: 0;
  padding: 0;
}

.single-domain-head-left {
  width: 100%;
  text-align: justify;
}

.single-domain-head-youtube {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: #fff;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.single-domain-head-youtube a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}

.single-domain-body-head h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.single-domain-body-head p {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Domain verification */

.domain-verification-container {
  margin: 80px 0px 40px;
  width: 100%;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.domain-verification-container table {
  padding: 20px 10px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td {
  background: #fff;
  padding: 10px;
}

td p {
  display: flex;
  align-items: center;
}

.copy-icon {
  margin-left: 5px;
  cursor: pointer;
}

.dkim-paragraph {
  width: 500px;
  overflow-x: auto;
}

.btn-text {
  padding: 5px 10px;
  border: none;
  outline: none;
  background: #ffd7d4;
}

.btn-text.green {
  background: green;
  color: white;
}

/* .green {
  background: green;
  color: white;
  background-color: green !important;
} */

.pending-btn {
  border: none;
  outline: none;
  border-radius: 99px;
  background-color: #ccc;
  padding: 5px 12px;
}

.pending-btn.green {
  background: green;
  color: white;
}

.verify-now-btn button {
  border: none;
  outline: none;
  padding: 10px 20px;
  color: #fff;
  background: #009688;
}

.arrow-icons {
  font-size: 24px;
  margin-left: 6px;
  margin-top: 5px;
}

@media all and (min-width: 768px) {
  .single-domain-container {
    padding: 0px;
  }

  .single-domain-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    margin-bottom: 20px;
    background-color: rgb(241, 235, 235);
  }

  .single-domain-head-left {
    width: 70%;
    text-align: justify;
  }

  .single-domain-head-left {
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .single-domain-head-youtube {
    margin-bottom: 0px;
  }
}
