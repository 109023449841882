.service-section-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px;
}

.service-section-main-content {
  text-align: center;
  width: 47%;
}

.service-section-title {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 1.8rem;
  line-height: 1.4em;
}

.service-section-text {
  font-size: 1.1rem;
  padding: 20px 0px;
}

.service-section-img img {
  height: 80%;
  object-fit: cover;
  margin-top: 30px;
}

.service-section-services {
  width: 47%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
}

.service-section-services .services-row .service-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
}

.services-row-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.service-item img {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  width: 60px;
  margin-bottom: 15px;
}

@media (max-width: 1050px) {
  .service-section-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .service-section-services {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
  }

  .service-section-main-content {
    text-align: center;
    width: 90%;
  }
}

@media (max-width: 460px) {
  .service-section-services {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
  }
  .service-section-services .services-row .service-item {
    width: 100%;
    display: block;
  }

  .service-section-container {
    margin: 50px 0px;
  }
}
