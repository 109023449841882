.pre-footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
}

.pre-footer-left {
  width: 47%;
  display: flex;
  justify-content: space-between;
}

.pre-footer-right {
  width: 47%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1050px) {
  .pre-footer-container {
    display: flex;
    flex-direction: column;
  }

  .pre-footer-left {
    width: 90%;
    margin-bottom: 30px;
  }
  .pre-footer-right {
    width: 90%;
  }
}

@media (max-width: 450px) {
  .pre-footer-left {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pre-footer-right {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
