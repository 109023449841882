.active-btn {
  font-size: 15px;
  padding: 8px 16px;
  margin-left: 0px;
  margin-right: 15px;
  background: #ccc;
  border: none;
  outline: none;
  border-radius: 99px;
  cursor: pointer;
  margin-bottom: 0px;
}

.active-btn.green {
  background-color: green;
  color: #fff;
}

.view {
  font-size: 15px;
  padding: 10px;
  margin-right: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 0px;
}

.view.green {
  background-color: green;
  color: #fff;
}

.expandmore {
  font-size: 15px;
  padding: 6px 12px;
  border: none;
  outline: none;
  cursor: pointer;
}

.domain-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.domain-table-container.lightgreen {
  background-color: #2171211a;
}

.domain-table-container:last-child {
  border-bottom: none;
}

.domain-table-domain .MuiSvgIcon-root,
.domain-table-dkim .MuiSvgIcon-root,
.domain-table-spf .MuiSvgIcon-root {
  font-weight: 600;
  color: red;
}

.domain-table-domain .green,
.domain-table-dkim .green,
.domain-table-spf .green {
  font-weight: 600;
  color: green;
}

.domain-table-domain,
.domain-table-dkim,
.domain-table-spf {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
}

.domain-table-title {
  margin-top: 10px;
}

.domain-table-title p {
  color: #666;
}

.domain-table-title h4 {
  font-size: 18px;
  color: #00838f;
}

.domain-table-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.domain-table-btn-inner {
  display: flex;
  align-items: center;
}

.domain-table-title,
.domain-table-domain,
.domain-table-dkim,
.domain-table-spf {
  width: 60%;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
}

@media all and (min-width: 768px) {
  .active-btn {
    margin-left: 80px;
    margin-right: 80px;
  }

  .domain-main-container {
    margin-top: 10px;
  }
  .domain-table-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    padding: 20px 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .domain-table-btn-group {
    margin-top: 0px;
  }

  .domain-table-title {
    margin-top: 0px;
  }

  .domain-table-title,
  .domain-table-domain,
  .domain-table-dkim,
  .domain-table-spf {
    width: auto;
    padding: 0px;
    margin-bottom: 0px;
    border: none;
  }

  .domain-table-container:last-child {
    border-bottom: 1px solid #ccc;
  }
}
