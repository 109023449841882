.contact-info-container {
  padding: 170px 10px 20px;
  padding-bottom: 80px;
}

.contact-info-title {
  font-size: 30px;
  margin: 0;
  margin: 30px 0px 20px;
  color: #009688;
}

.contact-form-container-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

select {
  cursor: pointer;
  background-color: rgba(109, 124, 144, 0.1);
}

input,
select {
  border: 0;
  outline: 0;
  font-size: 13px;
  padding: 7px 12px;
  border-color: #bbb;
  border-radius: 5px;
  border: 2px solid #ccc;
}

input:hover,
input:focus,
select:hover,
select:foucs {
  border-color: #aaa;
}

.input-group {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.input-group label {
  font-size: 15px;
  margin-bottom: 6px;
}

.input-group label span {
  color: red;
}

.billing-title {
  justify-items: left;
  justify-self: left;
  text-align: left;
}

.form-button {
  padding: 7px 12px;
  background-color: #009688;
  border-color: #009688;
  color: #fff;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  float: right;
  cursor: pointer;
}

.contact-form-name {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

@media all and (min-width: 768px) {
  .contact-info-container {
    padding: 0px;
    padding-bottom: 40px;
  }
  .contact-form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .contact-form-container-left {
    width: 47%;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact-form-container-right {
    width: 47%;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact-form-name {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .contact-form-name .input-group {
    width: 48%;
  }
}
