.dashboard-main-content {
  padding: 30px 10px;
  padding-top: 170px;
  background: #f0f5ff;
}

.notification-area {
  padding: 15px 10px 18px;
  width: 100%;
  background: #fdcdd282;
  color: #6f7174;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-area p {
  font-size: 0.9rem;
}

.notification-area span {
  cursor: pointer;
}

.loading-progress {
  position: fixed;
  top: 140px;
  left: 0px;
  z-index: 9999;
}

.hide-notification {
  display: none;
}

.single-notification-content-body {
  padding: 20px;
  background: #fff;
  margin: 15px 0px;
}

.notification-stepper-container {
  margin-top: 140px;
  position: fixed;
  background-color: #fff;
  padding: 10px 6px 20px;
  width: 100%;
}

.subscriptions-notification-dashboard {
  position: sticky;
  top: 136px;
  width: 100%;
  z-index: 99;
}

.subscription-notification {
  padding: 0;
  margin-top: 0px;
}

.resend-email {
  display: inline-block;
  padding: 5px 10px;
  margin-top: 6px;
  margin-left: 8px;
  color: white;
  background: linear-gradient(495deg, #46b3ff 0, #0752fe 100%);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s linear;
}

.resend-email:hover,
.resend-email:focus {
  background: linear-gradient(495deg, #1a8cdd 0, #215be2 100%);
}

@media all and (min-width: 768px) {
  .dashboard-main {
    box-sizing: border-box;
    width: 80%;
  }

  .dashboard-main-box-container {
    width: 95%;
    margin: 0 auto;
  }

  .dashboard-main-box-container.body-container {
    padding: 85px 0px 30px;
  }

  .dashboard-main-box-container.body-container.extra-padding {
    padding: 115px 0px 40px;
  }

  .dashboard-main-content {
    padding: 20px 30px;
    padding-top: 50px;
  }

  .dashboard-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }

  .notification-area {
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .notification-area p {
    font-size: 1.2rem;
  }

  .notification-area span {
    cursor: pointer;
  }

  .resend-email {
    display: inline-block;
    padding: 5px 10px;
    margin-top: 6px;
    margin-left: 8px;
    color: white;
    background: linear-gradient(495deg, #46b3ff 0, #0752fe 100%);
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s linear;
  }

  .resend-email:hover,
  .resend-email:focus {
    background: linear-gradient(495deg, #1a8cdd 0, #215be2 100%);
  }

  .loading-progress {
    position: sticky;
    top: 73px;
    left: 0px;
    z-index: 9999999;
  }

  .body-container.loading-padding {
    padding-top: 80px;
  }

  .hide-notification {
    display: none;
  }

  .subscriptions-notification-dashboard {
    margin-top: 69px;
    position: sticky;
    top: 73px;
    width: 100%;
    z-index: 99;
  }

  .subscription-notification {
    padding: 0;
    margin-top: 0px;
  }

  .custom-area-notification {
    padding: 15px;
  }
}
