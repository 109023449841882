.bulk-verifier-container {
  width: 60%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  margin: 0 auto;
}

.bulk-verifier-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.bulk-verifier-textarea {
  width: 100%;
  height: 150px;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 1.2rem;
}

.bulk-verifier-submit {
  width: 100%;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 0.8rem;
  background-color: #2f93c5;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .bulk-verifier-container {
    width: 90%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0 auto;
  }
}
