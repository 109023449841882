.pagenotfound-container {
  padding: 120px 10px 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-title {
  font-size: 100px;
  font-weight: 900;
  color: red;
}

.secondary-title {
  font-size: 30px;
  margin-bottom: 10px;
}

.pagenotfound-container p {
  font-size: 20px;
}

.back-to-home {
  padding: 12px 30px;
  border: none;
  outline: none;
  color: #fff;
  background-color: #00838f;
  margin-top: 20px;
  border-radius: 99px;
  font-size: 18px;
  cursor: pointer;
}
