.domain-head h2 {
  font-size: 30px;
  margin: 0;
  margin-bottom: 30px;
}

.domain-container h2 {
  font-size: 30px;
  margin: 0;
  margin-bottom: 30px;
}

.domain-menuhead {
  display: flex;
  align-items: center;
}

.domain-menuhead-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.domain-menuhead-item.active {
  border: none;
  outline: none;
  border: 1px solid #999;
  border-radius: 10px;
  padding: 5px 12px;
  background-color: #00838f;
  color: #fff;
}

.domain-menuhead-item-icon {
  margin-right: 2px;
}

.horizontal-line {
  border: 1px solid #999;
  width: 100%;
  background-color: #999;
  color: #999;
  margin: 10px 0px;
}

.domain-container-bodyhead {
  display: flex;
  align-items: center;
  color: #241c15;
  font-weight: 500;
  /* margin-top: 40px; */
}

.domain-container-bodyhead h2 {
  padding: 0;
  margin: 0;
}

.list-icon.MuiSvgIcon-root {
  font-size: 40px;
}

.domain-paragraph {
  font-size: 18px;
  margin: 10px 0 20px;
}
