.login-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 10px 0px;
}

.login-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 20px;
  border-radius: 8px;
  background-color: #fff;
  -webkit-transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 24px;
  box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%);
}

.login-logo {
  width: 70%;
  display: flex;
  margin: auto;
  cursor: pointer;
}

.login-card {
  margin-top: 20px;
}

.login-container input[type="text"],
.login-container input[type="password"] {
  display: block;
  border: none;
  border-radius: 5px;
  background: rgba(109, 124, 144, 0.1);
  width: 100%;
  margin: 16px 0;
  padding: 8px 12px;
  text-align: left;
  border: 2px solid transparent;
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.8em;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #20292f;
  outline: none;
}

.login-container input[type="text"]:hover,
.login-container input[type="password"]:hover {
  border: 2px solid #3776ff;
}

.forgot-text {
  text-align: center;
  margin-top: 20px;
  color: #6c7d90;
  font-size: 18px;
}

.login-btn {
  width: 100%;
  border: 0;
  outline: 0;
  border-radius: 9999px;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  background: linear-gradient(120deg, rgba(103, 151, 255, 0) 0%, #4d86ff 100%);
  background-color: #67b5ff;
  cursor: pointer;
}

.login-error {
  font-size: 18px;
  color: red;
  margin: 20px auto -10px;
}

@media all and (min-width: 768px) {
  .login-container {
    width: 37%;
  }
}
