.dashboard-main-container {
  display: flex;
  flex-direction: column;
}

.Dashboard-main-content {
  display: flex;
  flex-direction: column;
}

@media all and (min-width: 768px) {
  .Dashboard-main-content {
    display: flex;
    flex-direction: row;
  }
}
