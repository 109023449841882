.sending-domain-body input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px 12px;
  margin: 20px 0px 14px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.sending-domain-body button {
  padding: 8px 30px;
  background: #00838f;
  color: #fff;
  font-size: 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 9px;
  cursor: pointer;
}

.sending-domain-body h2 {
  font-size: 30px;
  margin: 0;
  margin: 20px 0px 10px;
}

.sending-domain-close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  .sending-domain-modal {
    background-color: #fff;
    width: 80%;
    padding: 20px;
    margin: 60px;
    position: relative;
  }

  .sending-domain-close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
}

@media all and (max-width: 420px) {
  .sending-domain-modal {
    background-color: #fff;
    width: 90%;
    padding: 20px;
    margin: 20px;
    margin-top: 150px;
    position: relative;
  }

  .sending-domain-close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
}
