.profile-main-container {
  padding: 170px 10px 80px;
}

.profile-container img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.btn-group {
  display: flex;
  flex-direction: row;
}

.profile-btn {
  padding: 5px 12px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 5px;
}

.profile-upload {
  background-color: #009688;
  margin-right: 10px;
}

.profile-remove {
  background-color: #444444;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-container p {
  margin: 10px 0px;
}

@media all and (min-width: 768px) {
  .profile-main-container {
    padding: 0;
    padding-bottom: 40px;
  }
  .profile-content-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .profile-content-left,
  .profile-content-middle,
  .profile-content-right {
    width: 32%;
  }
}
