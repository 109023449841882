.dashboard-container {
  width: 95%;
  text-align: center;
}

.dashboard-footer {
  background: linear-gradient(350deg, #46b3ff 0, #0752fe 100%);
  color: #fff;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
