@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #241c15;
  /* box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%);
  background-image: linear-gradient(
    rgba(103 151 255 / 11%),
    rgba(103 151 255 / 11%)
  ); */
}

a:hover,
a:focus {
  text-decoration: none !important;
  color: #24151c !important;
}

/* scroll problem starts */

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: absolute;
  z-index: 999999;
  top: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.51) !important;
  overflow-y: auto;
}

/* scroll problem ends */

/* background-color: #00838f; input:: background: rgba(109,124,144,.1) */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(270deg, #82aac7 0, #98b0e6 100%);
}

.banner {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
  background-image: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.email-heading-content {
  text-align: center;
  width: 70%;
}

.email-heading-content .email-verifier-title {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
}

.email-heading-content .email-verifier-subtitle {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  margin-top: 20px;
}

.banner-container-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 47px;
  padding-top: 15px;
  background: linear-gradient(0deg, #fbfbfe, #fbfbfe);
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px #455d7314;
}

.banner-container-form.extra-padding {
  padding: 47px;
}

.banner-container-form-title-text {
  font-size: 1.6rem;
  font-weight: bold;
  color: #000;
  margin-left: 10px;
}

.banner-container-form-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.banner-container-form {
  margin-top: 60px;
  width: 70%;
}

.banner-container-form form {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.banner-container-form form input {
  width: 100%;
  padding: 10px;
  font-size: 19px;
  border-color: #adadad2e;
}
.banner-container-form form input:hover {
  border-color: #2a3fe687;
}
.banner-container-form-button {
  padding: 12px 25px;
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  color: #fff;
  font-weight: 600;
  border: 0;
  outline: 0;
  border-radius: 10px;
  margin-left: 10px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 20px;
}
.banner-container-form-button:hover {
  background: linear-gradient(9deg, #1990e6 0, #0f50e7eb 100%);
}

.banner-container-form-button.disbaled-form-button {
  cursor: not-allowed;
  opacity: 0.4;
}

.rechapcha-container {
  margin: -11px 0px 33px;
}

.or-divider {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
  text-transform: capitalize;
  color: #fff;
}

.email-verifier-upload button {
  padding: 12px 25px;
  background-image: linear-gradient(
    60deg,
    #7a1ff49c 0%,
    #bb5ed9de 100%
  ) !important;
  color: #fff;
  font-weight: 600;
  border: 0;
  outline: 0;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 28px;
}
.bulk-verify {
  color: #fbfbfe;
  cursor: pointer;
  margin-top: 37px;
  background-color: #f0f8ff2e;
  padding: 10px;
  border-radius: 4px;
}

.bulk-verify:hover {
  color: #fbfbfe;
  cursor: pointer;
  background-color: #f0f8ff53;
}

@media (max-width: 1050px) {
  .banner-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .banner-container-form {
    width: 100%;
  }
  .banner-container-image {
    width: 100%;
  }

  .banner-container-form {
    margin-top: 50px;
  }

  .email-heading-content {
    width: 90%;
  }
}

@media (max-width: 460px) {
  .banner-container-form-title {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }

  .banner-container-form form {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20px;
  }

  .banner-container-form-button {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .banner-container-form {
    padding: 40px 20px;
  }

  .banner-container-form-title-text {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
    margin-left: 0px;
    margin-top: 10px;
  }

  .email-heading-content {
    text-align: center;
    width: 100%;
    margin-top: 100px;
  }

  .email-heading-content .email-verifier-subtitle {
    font-size: 24px;
  }

  .email-heading-content .email-verifier-title {
    font-size: 40px;
  }

  .email-verifier-upload button {
    padding: 12px 20px;
    font-size: 16px;
  }
}

.footer-container {
  padding: 8px 0;
  background-color: #1f1f1f;
}

.footer-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.footer-column.text,
.react-icon {
  color: #fff;
}

.react-icon {
  font-size: 2rem;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.2s linear;
}

.react-icon:hover,
.react-icon:focus {
  color: #089db8;
}

@media (max-width: 1050px) {
  .footer-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .footer-column.text {
    margin-bottom: 20px;
  }
}

@media (max-width: 450px) {
  .react-icon:first-child {
    margin-left: 0;
  }
  .footer-column.text {
    font-size: 15px;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: #fff;
  padding: 1rem 7%;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  font-family: "Artifika", Georgia, "Times New Roman", serif;
  font-weight: bold;
  transition: 0.3s linear;
}

header .logo {
  font-size: 2rem;
  font-weight: bolder;
  color: #666;
}

header img {
  width: 16rem;
  object-fit: cover;
}

header .navbar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex: 1 1;
          flex: 1 1;
}

header .navbar a {
  font-size: 1.2rem;
  margin-left: 1rem;
  color: #666;
  text-decoration: none;
}

header .navbar a:hover {
  color: var(--red);
}

#menu-bar {
  font-size: 1.6rem;
  cursor: pointer;
  color: #666;
  border: 0.1rem solid #666;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem 0;
  display: none;
}

@media (max-width: 1050px) {
  header {
    padding: 2rem;
    font-size: 55%;
  }
}

@media (max-width: 925px) {
  #menu-bar {
    display: inline;
    display: initial;
  }

  header .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #f7f7f7;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 1rem;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: 0.3s linear;
  }

  header .navbar.active {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  header .navbar a {
    width: 100%;
    margin: 1rem;
    padding: 0.9rem;
    display: block;
    border: 0.2rem solid rgba(0, 0, 0, 0.1);
    border-left: 0.5rem solid #c756ec;
    background: #fff;
  }

  .active {
    border-bottom: 0;
  }
}

@media (max-width: 460px) {
  header {
    padding: 1rem;
  }
  header img {
    width: 14rem;
    object-fit: cover;
  }
}

.pre-footer-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
}

.pre-footer-left {
  width: 47%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.pre-footer-right {
  width: 47%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media (max-width: 1050px) {
  .pre-footer-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .pre-footer-left {
    width: 90%;
    margin-bottom: 30px;
  }
  .pre-footer-right {
    width: 90%;
  }
}

@media (max-width: 450px) {
  .pre-footer-left {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .pre-footer-right {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

.service-section-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 50px;
}

.service-section-main-content {
  text-align: center;
  width: 47%;
}

.service-section-title {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 1.8rem;
  line-height: 1.4em;
}

.service-section-text {
  font-size: 1.1rem;
  padding: 20px 0px;
}

.service-section-img img {
  height: 80%;
  object-fit: cover;
  margin-top: 30px;
}

.service-section-services {
  width: 47%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 150px;
}

.service-section-services .services-row .service-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
}

.services-row-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.service-item img {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  width: 60px;
  margin-bottom: 15px;
}

@media (max-width: 1050px) {
  .service-section-container {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .service-section-services {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 50px;
  }

  .service-section-main-content {
    text-align: center;
    width: 90%;
  }
}

@media (max-width: 460px) {
  .service-section-services {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 50px;
  }
  .service-section-services .services-row .service-item {
    width: 100%;
    display: block;
  }

  .service-section-container {
    margin: 50px 0px;
  }
}

.verify-section {
  min-height: 80vh;
  background-image: linear-gradient(60deg, #8834fd 0%, #c756ec 100%) !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.verify-section-container {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 0px;
}

.verify-section-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
}

.verify-section-content-title {
  font-size: 2.6rem;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  line-height: 1.4em;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}

.verify-section-content-text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #fff;
}

.verify-section-content button {
  width: 180px;
  padding: 20px 30px;
  border-radius: 6px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  outline: 0;
  border: 2px solid #fff;
  color: #fff;
  margin-top: 30px;
  cursor: pointer;
  transition: 0.2s linear;
}

.verify-section-content button:hover,
.verify-section-content button:focus {
  background-color: hsla(0, 0%, 100%, 0.2);
  border: 0;
  outline: 0;
}

@media (max-width: 1050px) {
  .verify-section-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-bottom: 0px;
  }

  .verify-section-content {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: left;
  }

  .verify-section {
    min-height: 60vh;
  }

  .verify-section-content-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 460px) {
  .verify-section {
    min-height: 70vh;
  }
  .verify-section-container {
    width: 100%;
  }

  .verify-section-content-title {
    font-size: 1.7rem;
    line-height: 1.2em;
  }

  .verify-section-content button {
    padding: 16px 30px;
  }
}

section {
  padding: 2rem 7%;
}

@media (max-width: 925px) {
  section {
    padding: 2rem;
  }
}

@media (max-width: 460px) {
  section {
    padding: 1rem;
  }
}

.validation-page-container {
  min-height: 90vh;
  padding: 120px 7% 20px;
  margin: 0 auto;
}

.validation-page-adds {
  border: 1px solid rgb(248, 248, 248);
}

.validation-page-info-container {
  font-size: 1.1rem;
  margin: 0rem 0 2rem;
  box-shadow: 0 1px 0px -4px rgb(0 0 0 / 10%), 0 2px 4px 2px rgb(0 0 0 / 10%);
  padding: 2.5rem 1rem;
}

.validation-page-info-title {
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
  font-weight: bold;
}

.validation-page-info-content h4 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
}

.validation-page-info-content div {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  word-wrap: break-word;
}
.validation-page-info-content div .valid {
  color: green;
}

.validation-page-info-content div .invalid {
  color: red;
}

.validation-page-info-footer {
  margin-top: 1.4rem;
}

.validation-page-info-footer h4 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
}

.validation-page-info-footer button {
  padding: 0.8rem 2rem;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  border: none;
  outline: none;
  background: #7357eb;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 0.5rem;
  margin-top: 0.9rem;
}

.error-message-validateion {
  width: 100%;
  text-align: center;
  background-color: rgba(243, 14, 64, 0.6);
  /* color: #fff; */
  padding: 0.8rem;
  margin-bottom: 1.4rem;
  font-weight: 600;
}
@media (max-width: 450px) {
  .validation-page-container {
    padding: 120px 1rem 20px;
  }

  .validation-page-info-title {
    font-size: 1.5rem;
  }

  .validation-page-info-footer h4 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
  }

  .validation-page-info-content h4 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
  }

  .validation-page-info-content div {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .validation-page-info-content div .icon {
    font-size: 0.2rem;
  }
}

.dashboard-container {
  width: 95%;
  text-align: center;
}

.dashboard-footer {
  background: linear-gradient(350deg, #46b3ff 0, #0752fe 100%);
  color: #fff;
  width: 100%;
  height: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 99999;
}

.profile-head-container a {
  text-decoration: none;
  color: #000;
}

.contactbook-icon {
  margin-right: 5px;
}

.icons {
  margin-right: 4px;
}

.profile-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.profile-head-container nav ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  list-style: none;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.profile-head-container nav li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-right: 30px;
  transition: all 0.2s linear;
}

.active {
  border-bottom: 2px solid #00838f;
}

.profile-head-containernav ul li a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  color: #000;
}

.profile-head-containernav ul li a:hover,
.profile-head-containernav ul li a:focus {
  text-decoration: none;
  color: #000;
}

@media all and (min-width: 768px) {
  .profile-head-container nav ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
  }
}

.contact-info-container {
  padding: 170px 10px 20px;
  padding-bottom: 80px;
}

.contact-info-title {
  font-size: 30px;
  margin: 0;
  margin: 30px 0px 20px;
  color: #009688;
}

.contact-form-container-left {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

select {
  cursor: pointer;
  background-color: rgba(109, 124, 144, 0.1);
}

input,
select {
  border: 0;
  outline: 0;
  font-size: 13px;
  padding: 7px 12px;
  border-color: #bbb;
  border-radius: 5px;
  border: 2px solid #ccc;
}

input:hover,
input:focus,
select:hover,
select:foucs {
  border-color: #aaa;
}

.input-group {
  width: 100%;
  margin-bottom: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.input-group label {
  font-size: 15px;
  margin-bottom: 6px;
}

.input-group label span {
  color: red;
}

.billing-title {
  justify-items: left;
  justify-self: left;
  text-align: left;
}

.form-button {
  padding: 7px 12px;
  background-color: #009688;
  border-color: #009688;
  color: #fff;
  border: none;
  outline: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  float: right;
  cursor: pointer;
}

.contact-form-name {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
}

@media all and (min-width: 768px) {
  .contact-info-container {
    padding: 0px;
    padding-bottom: 40px;
  }
  .contact-form-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
  }
  .contact-form-container-left {
    width: 47%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .contact-form-container-right {
    width: 47%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .contact-form-name {
    width: 100%;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .contact-form-name .input-group {
    width: 48%;
  }
}

.notification-search-icon {
  margin-top: 6px;
}

.notification-container {
  color: #999;
  width: 320px;
  margin: 0 auto;
  height: 200vh;
  border: 1px solid #ccc;
  background-color: #e8eff9;
  transition: all 0.3s ease-in;
  scroll-behavior: smooth;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}

.notification-container h4 {
  color: #000;
}

.notification-container.active {
  top: 0;
  right: 0;
}

.notification-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: linear-gradient(260deg, #46b3ff 0, #0752fe 100%);
  color: #fff;
  padding: 20px 30px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  width: 320px;
  z-index: 1;
}

.notification-header .fa-search {
  margin-right: 16px;
  cursor: pointer;
}

.notification-header .fa-times {
  cursor: pointer;
}

.notification-body-header {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.notification-body-header button {
  width: 100%;
  box-sizing: border-box;
  padding: 14px 0px;
  margin: 20px 0px;
  font-size: 16px;
  background: #f6f9fc;
  color: #7d9ab3;
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 0 0 2 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.notification-body {
  padding: 0 10px 30px;
  position: relative;
  overflow-y: auto;
}

.notification-body-content {
  height: 100%;
  color: #000;
  background-color: #fff;
  padding: 20px 20px 30px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.notification-body-content-img img {
  width: 100%;
  margin: 10px 0px 30px;
}

.notification-input {
  padding: 2px;
  background-color: #fff;
  margin: 20px 10px 0px;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 18px;
  display: none;
  transition: all 0.45s ease-in;
  border: 1px solid #ccc;
}

.notification-input input {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
}

.notification-input .fa-search {
  color: #999;
  margin-right: 10px;
  cursor: pointer;
}

.notification-input.active {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media all and (min-width: 768px) {
  .notification-container {
    width: 500px;
  }

  .notification-header {
    padding: 22px 30px;
    width: 100%;
  }

  .notification-input.active {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

.dashboard-main-content {
  padding: 30px 10px;
  padding-top: 170px;
  background: #f0f5ff;
}

.notification-area {
  padding: 15px 10px 18px;
  width: 100%;
  background: #fdcdd282;
  color: #6f7174;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.notification-area p {
  font-size: 0.9rem;
}

.notification-area span {
  cursor: pointer;
}

.loading-progress {
  position: fixed;
  top: 140px;
  left: 0px;
  z-index: 9999;
}

.hide-notification {
  display: none;
}

.single-notification-content-body {
  padding: 20px;
  background: #fff;
  margin: 15px 0px;
}

.notification-stepper-container {
  margin-top: 140px;
  position: fixed;
  background-color: #fff;
  padding: 10px 6px 20px;
  width: 100%;
}

.subscriptions-notification-dashboard {
  position: -webkit-sticky;
  position: sticky;
  top: 136px;
  width: 100%;
  z-index: 99;
}

.subscription-notification {
  padding: 0;
  margin-top: 0px;
}

.resend-email {
  display: inline-block;
  padding: 5px 10px;
  margin-top: 6px;
  margin-left: 8px;
  color: white;
  background: linear-gradient(495deg, #46b3ff 0, #0752fe 100%);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s linear;
}

.resend-email:hover,
.resend-email:focus {
  background: linear-gradient(495deg, #1a8cdd 0, #215be2 100%);
}

@media all and (min-width: 768px) {
  .dashboard-main {
    box-sizing: border-box;
    width: 80%;
  }

  .dashboard-main-box-container {
    width: 95%;
    margin: 0 auto;
  }

  .dashboard-main-box-container.body-container {
    padding: 85px 0px 30px;
  }

  .dashboard-main-box-container.body-container.extra-padding {
    padding: 115px 0px 40px;
  }

  .dashboard-main-content {
    padding: 20px 30px;
    padding-top: 50px;
  }

  .dashboard-btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 20px;
  }

  .notification-area {
    padding: 20px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .notification-area p {
    font-size: 1.2rem;
  }

  .notification-area span {
    cursor: pointer;
  }

  .resend-email {
    display: inline-block;
    padding: 5px 10px;
    margin-top: 6px;
    margin-left: 8px;
    color: white;
    background: linear-gradient(495deg, #46b3ff 0, #0752fe 100%);
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s linear;
  }

  .resend-email:hover,
  .resend-email:focus {
    background: linear-gradient(495deg, #1a8cdd 0, #215be2 100%);
  }

  .loading-progress {
    position: -webkit-sticky;
    position: sticky;
    top: 73px;
    left: 0px;
    z-index: 9999999;
  }

  .body-container.loading-padding {
    padding-top: 80px;
  }

  .hide-notification {
    display: none;
  }

  .subscriptions-notification-dashboard {
    margin-top: 69px;
    position: -webkit-sticky;
    position: sticky;
    top: 73px;
    width: 100%;
    z-index: 99;
  }

  .subscription-notification {
    padding: 0;
    margin-top: 0px;
  }

  .custom-area-notification {
    padding: 15px;
  }
}

.plans a {
  text-decoration: none;
  color: #999;
}

.user-popup {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.dashboard-main-nav {
  /* background: #7d63ec; */
  background: linear-gradient(350deg, #46b3ff 0, #0752fe 100%);
  color: #fff;
  padding: 23.5px 5px 12.5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  width: 100%;
  top: 62px;
  z-index: 9999;
  margin-bottom: 100px;
}

.add-money a {
  text-decoration: none;
  color: #f6533f;
  display: inline-block;
  width: 90px;
  background: #fff;
  border-radius: var(--border-radius);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding: 5px 10px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  position: relative;
  border-radius: 5px;
  border: 3px solid transparent;
}

.add-money a:hover {
  box-shadow: none;
  color: #f54a4a;
  border: 3px solid #dbdde3;
}

.dashboard-main-nav-left,
.dashboard-main-nav-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.nav-icons {
  margin-left: 2px;
  margin-right: 15px;
}
.nav-icons:hover {
  border-radius: 5px;
  background-color: #0000005e;
  transition: ease-in;
}

.dashboard-main-nav span {
  font-size: 14px;
}

.dashboard-main-nav-right img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 0px;
}

.popup-box {
  width: 120px;
  font-size: 0.9rem;
  background: #fff;
  text-align: left;
  border-radius: 6px;
  border: 1px solid rgba(182, 182, 182, 0.1);
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 13%);
}

.popup-box p {
  padding: 20px 20px;
  color: #000;
  transition: all 0.35s ease-in;
}

.popup-box p:hover,
.popup-box p:focus {
  background: #f8f9fa;
}

.popup-box.social-box {
  position: absolute;
  top: -145px;
  left: 10px;
  display: none;
  transition: all 750ms linear;
}

.popup-box.social-box.social-active {
  top: 75px;
  left: 10px;
  display: block;
}

.popup-box.user-box {
  width: 300px;
  position: absolute;
  top: -190px;
  right: 40px;
  display: none;
  transition: all 750ms linear;
}

.popup-box.user-box.user-active {
  display: block;
  top: 75px;
  right: 40px;
}

.notification-bell {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
}

.notification-num {
  height: 15px;
  width: 15px;
  color: #fff;
  background-color: green;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  top: 4px;
  right: 5px;
}

.bell-icon {
  color: #fff;
}

/* user box */

.user-box p {
  padding: 0;
  font-size: 1rem;
}

.user-box p,
button,
h4,
.line {
  margin-bottom: 10px;
}

.user-box h4 {
  color: rgb(27, 27, 27);
}

.line {
  width: 100%;
  height: 3px;
  background: #d6efe1;
}

.logout-btn {
  width: 100%;
  padding: 5px 0;
}

.btn {
  padding: 5px 10px;
  border: 0;
  outline: 0;
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 9999px;
}

.btn-monthly {
  background-color: #8cd622;
  margin-right: 6px;
}

.btn-yearly {
  background-color: #21c6c3;
}

.btn-change {
  background-color: #788665;
}

.plans {
  color: #999;
  font-size: 1rem;
  margin-bottom: 20px;
}

.plans .icons {
  font-size: 1.3rem;
  margin-right: 5px;
}

.plans .plan {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.plans .plan p {
  margin-top: 6px;
}

.user-box {
  padding: 20px 10px;
}

.credit-box {
  padding: 5px 0px;
  text-transform: capitalize;
}

.email-ammount {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  padding: 5px 8px;
  margin-right: 10px;
}

.email-added {
  border-left: 2px solid #999;
  padding-left: 10px;
  margin-left: 10px;
}

.message-box {
  color: #999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px 18px;
}

.message-box p {
  color: #000;
  padding: 0;
  margin: 0;
  margin-left: 4px;
}

.email-icon {
  margin-right: 4px;
}

.modal-class {
  position: absolute;
  top: 140px;
  left: 20%;
  z-index: 1000;
  width: 200px;
  height: auto;
  padding: 30px 20px;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #fff;
  color: #999;
  text-align: center;
}

.red-notification {
  background-color: red;
}

@media all and (min-width: 768px) {
  .dashboard-main-nav {
    padding: 13.5px 5px;
    width: 80%;
    top: 0px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .mobile-menu {
    display: none;
  }

  .dashboard-main-nav-right img {
    display: block;
  }

  .popup-box.social-box {
    top: -85px;
    left: 10px;
  }

  .popup-box.social-box.social-active {
    top: 75px;
    left: 10px;
  }

  .popup-box.user-box {
    top: -90px;
    right: 40px;
  }

  .popup-box.user-box.user-active {
    top: 75px;
    right: 40px;
  }

  .dashboard-main-nav {
    padding: 13.5px 30px;
  }

  .add-money a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    min-width: 120px;
    height: 36px;
    line-height: 30px;
    font-size: 12px;
  }

  .email-ammount {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-self: center;
            align-self: center;
    padding: 5px 15px;
    margin-right: 20px;
  }

  .modal-class {
    top: 80px;
    left: 70%;
  }

  .dashboard-main-nav-right img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-left: 20px;
  }
}

.dashboard-main-content-box {
  padding: 155px 10px 40px;
  overflow-x: hidden;
  overflow-y: auto;
}

.projects-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 20px;
}

.icon {
  height: 2rem;
  width: 2rem;
}

.project-card {
  width: 32%;
  padding: 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  color: #666;
  /* box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%); */
  /* box-shadow: 0 8px 2px 0 rgb(103 151 255 / 11%),
    0 12px 20px 0 rgb(103 151 255 / 11%); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
  border-radius: 10px;
}

.project-card:last-child {
  border-right: none;
}

.project-num {
  font-size: 24px;
  color: #999;
  margin: 6px 0;
  font-weight: bold;
}

.current-status {
  margin-top: 30px;
  color: #999;
}

.current-status-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  grid-gap: 3rem;
  gap: 3rem;
}

.status-card {
  width: 100%;
  border-radius: 20px;
  /* box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%); */
  /* box-shadow: 0 8px 2px 0 rgb(103 151 255 / 11%),
    0 2px 10px 0 rgb(103 151 255 / 11%); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
  padding: 40px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20px;
}

.status-logo .logo {
  font-size: 50px;
  color: #666;
  margin-right: 10px;
}

.status-text h4 {
  padding: 0;
  margin: 0;
  font-size: 30px;
}

.status-text p {
  padding: 0;
  margin: 0;
  font-size: 24px;
}

@media screen and (min-width: 768px) {
  .dashboard-main-content-box {
    box-sizing: border-box;
    padding: 30px 8px;
  }

  .current-status-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .status-card {
    width: 48%;
    margin-bottom: 0px;
  }

  .current-status {
    margin-top: 100px;
    color: #999;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.wrapper {
  display: table-cell;
  vertical-align: middle;
  margin-top: 80px;
}

.arrow-steps .step {
  font-size: 20px;
  text-align: center;
  color: #000;
  margin: 0 3px;
  padding: 20px 10px 20px 30px;
  min-width: 290px;
  float: left;
  position: relative;
  background-color: #b4e7ff;
  /* background-color: #ff5050; */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  width: 100%;
  cursor: pointer;
}

.arrow-steps {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 40px;
}
.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 33px solid transparent;
  border-bottom: 31px solid transparent;
  border-left: 17px solid #b4e7ff;
  /* border-left: 17px solid #ff5050; */
  z-index: 2;
  transition: border-color 0.2s ease;
}
.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}
.arrow-steps .step:first-child:before {
  border: none;
}
.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.arrow-steps .step span {
  position: relative;
}
.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
  color: #06ac77;
}
.arrow-steps .step.done span:before {
  opacity: 1;
  transition: opacity 0.3s ease 0.5s;
}
.arrow-steps .step.current {
  color: #fff;
  background-color: #ff5050;
}
.arrow-steps .step.current:after {
  border-left: 17px solid #ff5050;
}

.wrapper h4 {
  text-align: center;
  font-size: 18px;
  color: #ff5050;
}
@media (max-width: 765px) {
  .arrow-steps .step {
    min-width: 35px;
  }

  .arrow-steps .step {
    font-size: 10px;
    padding: 20px 10px 20px 24px;
    min-width: 33%;
  }

  .arrow-steps .step:after,
  .arrow-steps .step:before {
    border-top: 28px solid transparent;
    border-bottom: 24px solid transparent;
  }

  .arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -10px;
    color: #06ac77;
  }
}

@media (max-width: 375px) {
  .arrow-steps .step {
    font-size: 10px;
    padding: 20px 2px 20px 30px;
    min-width: 33%;
  }

  .arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -10px;
    color: #06ac77;
  }
}

.email-create-btn {
  display: inline-block;
  padding: 12px 24px;
  color: white;
  background: linear-gradient(495deg, #46b3ff 0, #0752fe 100%);
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.failsEmail {
  background: rgb(248, 38, 38) !important;
}

.green_row td {
  background: rgba(51, 174, 51, 0.14) !important;
}

.red_row td {
  background: rgba(228, 201, 201, 0.47) !important;
}
.email-create-btn:hover,
.email-create-btn:focus {
  background: linear-gradient(495deg, #0752fe 0, #46b3ff 100%);
}

.create-project-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.modal-class-project-name {
  position: absolute;
  top: 25%;
  left: 18px;
  width: 90%;
  z-index: 1000;
  height: auto;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #fff;
  color: #999;
}

.modal-class-add-email,
.modal-class-upload-email {
  position: absolute;
  top: 25%;
  left: 18px;
  width: 90%;
  z-index: 1000;
  height: auto;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #fff;
  color: #999;
}
.email-project-table th,
.email-project-table td {
  text-align: center !important;
}

.email-project-table td button {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* file upload */
.csv-file-upload {
  width: 100%;
  padding: 30px 0px;
  text-align: center;
  border-radius: 10px;
  border: 2px dotted #ccc;
  margin-top: 20px;
  margin-bottom: 10px;
}

.csv-upload-icon {
  font-size: 3.5rem;
  color: #668ce4;
}

.csv-drop-file h4 {
  font-size: 1.2rem;
}

.csv-or {
  color: #999;
  margin-bottom: 1rem;
}

#csv-file {
  width: 120px;
  cursor: pointer;
  outline: none !important;
  border: none !important;
  padding: 10px 20px;
  position: relative;
}

::-webkit-file-upload-button {
  color: #fff;
  background: linear-gradient(495deg, #0752fe 0, #46b3ff 100%);
  border: none;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  inset: 0;
  width: 100%;
}

/* file upload ends */

.project-details {
  min-height: 80vh;
  padding: 10px;
  margin-top: 145px;
}

.project-details table th,
.project-details table td {
  text-align: left !important;
  width: 50vw;
}

.create-project-modal-container textarea {
  border: 0;
  outline: 0;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}

.add-email-heading h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0752fe;
  margin-bottom: 10px;
}

.add-email-heading p {
  font-size: 1rem;
  color: #999;
  margin-bottom: 10px;
}

.bulk-email-row {
  -webkit-justify-content: center;
          justify-content: center;
  grid-column-gap: 3rem;
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
}

@media all and (min-width: 768px) {
  .modal-class-project-name {
    top: 40%;
    left: 33%;
    width: 500px;
    padding: 30px 20px;
  }
  .modal-class-add-email,
  .modal-class-upload-email {
    top: 24%;
    left: 33%;
    width: 500px;
    padding: 30px 20px;
  }
  .project-details {
    margin-top: 30px;
    min-height: 80vh;
    padding: 0px;
  }
}

.bulk-verifier-container {
  width: 60%;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 70vh;
  margin: 0 auto;
}

.bulk-verifier-container form {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.bulk-verifier-textarea {
  width: 100%;
  height: 150px;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 1.2rem;
}

.bulk-verifier-submit {
  width: 100%;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 0.8rem;
  background-color: #2f93c5;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .bulk-verifier-container {
    width: 90%;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100vh;
    margin: 0 auto;
  }
}

.billing-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 145px 10px 40px;
}

.billing-cards-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.billing-card {
  text-align: center;
  background-color: #fff;
  width: 100%;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  outline: none;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 0.3s linear;
}

.billing-card.green {
  border: 3px solid green;
}

.billing-card:hover {
  border: 2px solid blue;
}

.billing-card:first-child {
  margin-bottom: 20px;
}

.billing-card h5 {
  font-size: 24px;
  margin-bottom: 6px;
}

.billing-card span {
  font-size: 60px;
  color: #3772ff;
  line-height: 60px;
  font-weight: 900;
}

.billing-card sup sub {
  font-weight: 400;
  font-size: 0.9rem;
  color: #555;
}

.billing-card sup {
  font-size: 18px;
  vertical-align: auto;
  position: relative;
  top: -27px;
}

.billing-card sub {
  font-size: 18px;
}

.btn-grp {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
}

.btn-update button {
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  background: green;
  color: #fff;
  margin-right: 10px;
}

.btn-cancel button {
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  background: red;
  color: #fff;
}

.btn-cancel button:hover,
.btn-update button:hover {
  border: 3px solid #dbdde3;
}

.subscriptions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 25px;
}

.subscriptions-left {
  padding: 8px 0px;
  font-size: 18px;
}

.subscriptions-left h2 {
  margin-bottom: 12px;
}

.subscription-cancelation-btn {
  padding: 8px 20px;
  border: none;
  outline: none;
  background: red;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  text-transform: capitalize;
}

.modal-class-package {
  position: absolute;
  top: 14%;
  left: 35px;
  width: 80%;
  z-index: 1000;
  height: auto;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #fff;
  color: #999;
  position: relative;
}

.billing-section-addCredit-btn .btn {
  color: #ccc;
  border-radius: 4px;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1.5px solid #ccc;
  padding: 0.9rem 2rem;
  font-size: 1.8rem;
  background-size: 300%;
  transition: all 0.6s;
  border-radius: 10px;
  cursor: pointer;
}

.billing-section-addCredit-btn .btn.blue {
  color: #fff !important;
  background-image: linear-gradient(160deg, #46b3ff 50%, #0752fe 50%);
}

.billing-section-addCredit-btn .btn:hover {
  color: #fff !important;
  background-position: 100%;
  -webkit-transform: translateX(0.5rem);
          transform: translateX(0.5rem);
}

.billing-section-addCredit-btn .btn:active {
  color: #fff !important;
  -webkit-transform: translate(0.5rem, 0.5rem);
          transform: translate(0.5rem, 0.5rem);
}

.billing-section-addCredit-btn {
  margin: 60px 0px;
}

@media screen and (min-width: 768px) {
  .billing-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 20px;
  }

  .subscriptions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 0px;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .subscriptions-left {
    width: 70%;
    padding: 8px 0px;
    text-align: justify;
  }

  .billing-cards-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .billing-card {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 50%;
  }

  .billing-card:first-child {
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .modal-class-package {
    top: 12%;
    left: 33%;
    width: 500px;
    padding: 30px 20px;
    position: relative;
    overflow: hidden;
  }

  .current-status-billing {
    margin: 0;
    margin-bottom: 60px;
  }
}

.payment-modal-container {
  background: #fff;
  width: 90%;
  margin: 80px auto;
  border: none;
  outline: none;
}

hr {
  margin: 4px 0px;
}

.modal-body-title {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 35px 12px 5px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.paymentFrm {
  padding: 0px 10px;
}

.paymentFrm .form-group label {
  display: block;
  margin-bottom: 4px;
}

.paymentFrm .form-group input {
  display: block;
  width: 100%;
  margin-bottom: 4px;
  padding: 4px 20px;
  border: none;
  outline: none;
  border: 1px solid #ccc;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-card-used {
  padding: 0px 25px;
  text-align: center;
}

.modal-card-used h4 {
  margin: 0;
  margin-bottom: 5px;
}

.modal-card-used input {
  margin: 5px 0;
  margin-right: 5px;
}

.payment-success-button {
  width: 100%;
  display: inline-block;
  padding: 8px;
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  /* background: Linear-gradient(
    to bottom,
    rgba(248, 153, 57, 1) 0,
    rgba(238, 122, 6, 1) 100%
  ); */
  background: linear-gradient(350deg, #46b3ff 0, #0752fe 100%);
  cursor: pointer;
}

.payment-success-button.disabled {
  cursor: not-allowed;
}

.payment-footer {
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  color: #fff;
  text-align: center;
  padding: 10px 0px;
  margin-top: 15px;
}

.form-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.col-xs-4 {
  width: 32%;
}

.addCreditModalError {
  text-align: center;
  font-size: 14px;
  color: red;
}

@media all and (min-width: 768px) {
  .payment-modal-container {
    background: #fff;
    width: 90%;
    margin: 80px auto;
    border: none;
    outline: none;
  }

  .modal-body-title {
    padding: 10px 30px;
  }

  .paymentFrm {
    padding: 0px 30px;
  }

  .paymentFrm .form-group label {
    display: block;
    margin-bottom: 8px;
  }

  .paymentFrm .form-group input {
    display: block;
    width: 100%;
    margin-bottom: 12px;
    padding: 8px 20px;
    border: none;
    outline: none;
    border: 1px solid #ccc;
  }

  .form-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .payment-modal-container .col-xs-4 {
    width: 32%;
  }

  .payment-success-button {
    padding: 12px;
    margin-top: 20px;
  }

  .payment-footer {
    padding: 20px 0px;
  }

  .modal-card-used input {
    margin: 10px 0;
    margin-right: 10px;
  }

  hr {
    margin: 10px 0px;
  }

  .payment-modal-container {
    width: 35%;
  }
}

.domain-head h2 {
  font-size: 30px;
  margin: 0;
  margin-bottom: 30px;
}

.domain-container h2 {
  font-size: 30px;
  margin: 0;
  margin-bottom: 30px;
}

.domain-menuhead {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.domain-menuhead-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.domain-menuhead-item.active {
  border: none;
  outline: none;
  border: 1px solid #999;
  border-radius: 10px;
  padding: 5px 12px;
  background-color: #00838f;
  color: #fff;
}

.domain-menuhead-item-icon {
  margin-right: 2px;
}

.horizontal-line {
  border: 1px solid #999;
  width: 100%;
  background-color: #999;
  color: #999;
  margin: 10px 0px;
}

.domain-container-bodyhead {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #241c15;
  font-weight: 500;
  /* margin-top: 40px; */
}

.domain-container-bodyhead h2 {
  padding: 0;
  margin: 0;
}

.list-icon.MuiSvgIcon-root {
  font-size: 40px;
}

.domain-paragraph {
  font-size: 18px;
  margin: 10px 0 20px;
}

.active-btn {
  font-size: 15px;
  padding: 8px 16px;
  margin-left: 0px;
  margin-right: 15px;
  background: #ccc;
  border: none;
  outline: none;
  border-radius: 99px;
  cursor: pointer;
  margin-bottom: 0px;
}

.active-btn.green {
  background-color: green;
  color: #fff;
}

.view {
  font-size: 15px;
  padding: 10px;
  margin-right: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 0px;
}

.view.green {
  background-color: green;
  color: #fff;
}

.expandmore {
  font-size: 15px;
  padding: 6px 12px;
  border: none;
  outline: none;
  cursor: pointer;
}

.domain-table-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 20px 0px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.domain-table-container.lightgreen {
  background-color: #2171211a;
}

.domain-table-container:last-child {
  border-bottom: none;
}

.domain-table-domain .MuiSvgIcon-root,
.domain-table-dkim .MuiSvgIcon-root,
.domain-table-spf .MuiSvgIcon-root {
  font-weight: 600;
  color: red;
}

.domain-table-domain .green,
.domain-table-dkim .green,
.domain-table-spf .green {
  font-weight: 600;
  color: green;
}

.domain-table-domain,
.domain-table-dkim,
.domain-table-spf {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  color: #666;
}

.domain-table-title {
  margin-top: 10px;
}

.domain-table-title p {
  color: #666;
}

.domain-table-title h4 {
  font-size: 18px;
  color: #00838f;
}

.domain-table-btn-group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 10px;
}

.domain-table-btn-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.domain-table-title,
.domain-table-domain,
.domain-table-dkim,
.domain-table-spf {
  width: 60%;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
}

@media all and (min-width: 768px) {
  .active-btn {
    margin-left: 80px;
    margin-right: 80px;
  }

  .domain-main-container {
    margin-top: 10px;
  }
  .domain-table-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    text-align: left;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 20px 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .domain-table-btn-group {
    margin-top: 0px;
  }

  .domain-table-title {
    margin-top: 0px;
  }

  .domain-table-title,
  .domain-table-domain,
  .domain-table-dkim,
  .domain-table-spf {
    width: auto;
    padding: 0px;
    margin-bottom: 0px;
    border: none;
  }

  .domain-table-container:last-child {
    border-bottom: 1px solid #ccc;
  }
}

.sending-domain-body input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px 12px;
  margin: 20px 0px 14px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.sending-domain-body button {
  padding: 8px 30px;
  background: #00838f;
  color: #fff;
  font-size: 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 9px;
  cursor: pointer;
}

.sending-domain-body h2 {
  font-size: 30px;
  margin: 0;
  margin: 20px 0px 10px;
}

.sending-domain-close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  .sending-domain-modal {
    background-color: #fff;
    width: 80%;
    padding: 20px;
    margin: 60px;
    position: relative;
  }

  .sending-domain-close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
}

@media all and (max-width: 420px) {
  .sending-domain-modal {
    background-color: #fff;
    width: 90%;
    padding: 20px;
    margin: 20px;
    margin-top: 150px;
    position: relative;
  }

  .sending-domain-close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
}

.domain-container {
  padding: 145px 10px 40px;
}

.search-icon {
  color: #999;
}

/* .domain-container h2 {
  font-size: 30px;
  margin: 0;
  margin-bottom: 30px;
}

.domain-menuhead {
  display: flex;
  align-items: center;
}

.domain-menuhead-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.domain-menuhead-item.active {
  border: none;
  outline: none;
  border: 1px solid #999;
  border-radius: 10px;
  padding: 5px 12px;
  background-color: #00838f;
  color: #fff;
}

.domain-menuhead-item-icon {
  margin-right: 2px;
}

.horizontal-line {
  border: 1px solid #999;
  width: 100%;
  background-color: #999;
  color: #999;
  margin: 10px 0px;
}

.domain-container-bodyhead {
  display: flex;
  align-items: center;
  color: #241c15;
  font-weight: 500;
  margin-top: 40px;
}

.domain-container-bodyhead h2 {
  padding: 0;
  margin: 0;
}

.list-icon.MuiSvgIcon-root {
  font-size: 40px;
}

.domain-paragraph {
  font-size: 18px;
  margin: 10px 0 20px;
} */

.domain-table-head {
  padding: 20px 0px;
  margin: 60px 0px 0px;
}

.domain-table-head-left-one {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.domain-table-head-left-one p {
  font-size: 18px;
  margin: 0px 5px;
}

.domain-table-head-inputbox {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 10px 0px 20px;
}

.domain-table-head-input {
  border: none;
  outline: none;
  padding: 5px 10px;
  width: 100%;
}

.table-head-checkbox {
  width: 20px;
  height: 20px;
}

.domain-sending-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 10px;
  background: #00838f;
  color: #fff;
  width: 200px;
  cursor: pointer;
}

.table-head-select {
  width: 100px;
  border: none;
  outline: none;
  background: #ccc;
  padding: 10px;
  padding-left: 0px;
  border-radius: 1px;
}

.table-head-select option {
  border: none;
  outline: none;
  border-radius: 10px;
}

@media all and (min-width: 768px) {
  .domain-container {
    padding: 0px;
  }

  .domain-table-head {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .domain-table-head-left {
    width: 60%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .domain-table-head-inputbox {
    margin: 0px;
  }
  .domain-table-head-input {
    width: 300px;
  }

  .sending-domain-modal-container {
    border: 1px solid #ccc;
    width: 450px;
    height: auto;
    position: absolute;
    top: 100px;
    right: 36%;
    background-color: #fff;
    padding: 20px 15px;
  }
}

.single-domain-container {
  padding: 170px 10px 40px;
  color: #241c15;
}

.youtube-icon {
  margin: 0;
  padding: 0;
}

.single-domain-head-left {
  width: 100%;
  text-align: justify;
}

.single-domain-head-youtube {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: red;
  color: #fff;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.single-domain-head-youtube a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}

.single-domain-body-head h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.single-domain-body-head p {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Domain verification */

.domain-verification-container {
  margin: 80px 0px 40px;
  width: 100%;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.domain-verification-container table {
  padding: 20px 10px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td {
  background: #fff;
  padding: 10px;
}

td p {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.copy-icon {
  margin-left: 5px;
  cursor: pointer;
}

.dkim-paragraph {
  width: 500px;
  overflow-x: auto;
}

.btn-text {
  padding: 5px 10px;
  border: none;
  outline: none;
  background: #ffd7d4;
}

.btn-text.green {
  background: green;
  color: white;
}

/* .green {
  background: green;
  color: white;
  background-color: green !important;
} */

.pending-btn {
  border: none;
  outline: none;
  border-radius: 99px;
  background-color: #ccc;
  padding: 5px 12px;
}

.pending-btn.green {
  background: green;
  color: white;
}

.verify-now-btn button {
  border: none;
  outline: none;
  padding: 10px 20px;
  color: #fff;
  background: #009688;
}

.arrow-icons {
  font-size: 24px;
  margin-left: 6px;
  margin-top: 5px;
}

@media all and (min-width: 768px) {
  .single-domain-container {
    padding: 0px;
  }

  .single-domain-head {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 20px 30px;
    margin-bottom: 20px;
    background-color: rgb(241, 235, 235);
  }

  .single-domain-head-left {
    width: 70%;
    text-align: justify;
  }

  .single-domain-head-left {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 20px;
  }

  .single-domain-head-youtube {
    margin-bottom: 0px;
  }
}

.profile-main-container {
  padding: 170px 10px 80px;
}

.profile-container img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.btn-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.profile-btn {
  padding: 5px 12px;
  border: none;
  outline: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  border-radius: 5px;
}

.profile-upload {
  background-color: #009688;
  margin-right: 10px;
}

.profile-remove {
  background-color: #444444;
}

.profile-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.profile-container p {
  margin: 10px 0px;
}

@media all and (min-width: 768px) {
  .profile-main-container {
    padding: 0;
    padding-bottom: 40px;
  }
  .profile-content-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .profile-content-left,
  .profile-content-middle,
  .profile-content-right {
    width: 32%;
  }
}

.api-container {
  padding: 145px 10px 80px;
}

.api-item h4 {
  font-size: 20px;
  margin: 16px 0px;
}

.api-item p.red {
  padding: 10px 10px;
  padding-right: 20px;
  font-size: 15px;
  background-color: rgb(250, 248, 248);
  color: #c7254e;
  word-wrap: break-word;
}

h4.red {
  color: #c7254e;
}

.api-item a {
  width: auto;
  padding: 10px 10px;
  padding-right: 20px;
  font-size: 15px;
  color: #007c89;
  background-color: rgb(250, 248, 248);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  word-wrap: break-word;
}

.api-docs-message {
  padding: 15px 10px;
  margin: 20px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #e0f7fa;
}

.api-docs-btn button {
  background-color: #00897b;
  padding: 7px 12px;
  color: #fff;
  cursor: pointer;
  border: none;
  outline: none;
}

/* Table part */
.api-table-container p {
  padding-bottom: 20px;
}

.api-table-container {
  padding: 30px 0px 20px;
}

.api-table-container p {
  margin-bottom: 10px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  display: block;
  overflow-x: auto;
}

#customers td,
#customers th {
  padding: 0px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.generate-smtp-password {
  margin: 6px 0px 0px;
}

.generate-smtp-password button {
  width: 220px;
  padding: 8px 0px;
  background: linear-gradient(495deg, #46b3ff 0, #0752fe 100%);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
}
.generate-smtp-password button:hover {
  background: linear-gradient(495deg, #1a8cdd 0, #215be2 100%);
}

@media all and (min-width: 768px) {
  .api-container {
    padding: 0px;
    padding-bottom: 80px;
  }

  #customers {
    width: 100%;
    display: table;
  }
}

.emails-container {
  padding: 145px 10px 40px;
  min-height: 80vh;
}

.table-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.table-header-left {
  width: 100%;
  margin-bottom: 10px;
}

.table-header-right {
  width: 100%;
  margin-bottom: 10px;
}

.search {
  width: 280px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 4px 16px;
  border: 1px solid #ccc;
  border-radius: 99px;
}

.search input {
  border: none;
  outline: none;
}

.search .MuiSvgIcon-root {
  cursor: pointer;
}
/* Table part starts*/

.api-table-container {
  padding: 30px 0px 20px;
}

.api-table-container p {
  margin-bottom: 10px;
  font-size: 17px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  display: inline-block;
  overflow-x: auto;
}

#customers::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar*/
#customers {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#customers td,
#customers th {
  padding: 7px 10px;
  word-wrap: no-wrap;
  white-space: no-wrap;
  min-width: 146px;
  border: 1px solid #ccc;
  table-layout: fixed;
}

#customers th {
  background-color: #f2f2f2;
}

#customers tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

#customers .subject {
  min-width: 350px;
}

.view-email {
  width: 90px;
  padding: 7px 0px;
  cursor: pointer;
}

.date-format-box {
  min-width: 175px;
}

.clear-email-queue {
  padding: 8px 20px;
  cursor: pointer;
}

.no-email {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

/* Table part ends */

@media all and (min-width: 768px) {
  .emails-container {
    padding: 0px;
  }
  #customers {
    width: 100%;
    /* display: table; */
    overflow-x: auto;
    scrollbar-width: none;
    margin-bottom: 20px;
  }

  .table-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .table-header-left {
    width: 70%;
    margin-bottom: auto;
  }
  .table-header-right {
    width: 28%;
    margin-bottom: auto;
  }

  .search {
    width: 280px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
  }
}

.thanks-container {
  padding: 170px 10px 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.check-icon.MuiSvgIcon-root {
  font-size: 140px;
  color: green;
  margin: 0 auto;
}

.thanks-check {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.thanks-heading {
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 900;
}

.thanks-title {
  font-size: 18px;
}

.thanks-button {
  padding: 16px 30px;
  background-color: #00838f;
  color: #fff;
  border: none;
  outline: none;
  margin-top: 60px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
}

@media all and (min-width: 768px) {
  .thanks-container {
    padding: 0;
  }
}

.faqs-container {
  padding: 170px 10px 40px;
}

.faqs-heading {
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
}

.faqs-container hr {
  margin-bottom: 20px;
}

.faqs-item {
  margin-bottom: 20px;
}

.faqs-item h4 {
  font-size: 28px;
}

@media all and (min-width: 768px) {
  .faqs-container {
    padding: 0px;
  }
}

.pagenotfound-container {
  padding: 120px 10px 40px;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.main-title {
  font-size: 100px;
  font-weight: 900;
  color: red;
}

.secondary-title {
  font-size: 30px;
  margin-bottom: 10px;
}

.pagenotfound-container p {
  font-size: 20px;
}

.back-to-home {
  padding: 12px 30px;
  border: none;
  outline: none;
  color: #fff;
  background-color: #00838f;
  margin-top: 20px;
  border-radius: 99px;
  font-size: 18px;
  cursor: pointer;
}

.container {
  max-width: 1170px;
  margin: auto;
}
img {
  max-width: 100%;
}
.inbox_people {
  float: left;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}
.chat_ib h5 span {
  font-size: 13px;
  float: right;
}
.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
  cursor: pointer;
}

.chat_list:hover {
  background-color: #f8f8f8;
}
.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg {
  width: 100%;
}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}

.sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging {
  padding: 0 0 50px 0;
}
.msg_history {
  height: 516px;
  overflow-y: auto;
}

.pagination {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.pagination .previous {
  background-color: #f1f1f1;
  color: black;
  margin-right: 20px;
  padding: 8px 16px;
  cursor: pointer;
}

.pagination .next {
  background-color: #04aa6d;
  color: white;
  padding: 8px 16px;
  cursor: pointer;
}

.pagination .previous.disabled {
  cursor: not-allowed;
}

.pagination .next.disabled {
  cursor: not-allowed;
}

.bounceMessage {
  text-align: center;
  margin-bottom: 30px;
}

.total-bounce-message {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .messaging {
    margin-top: 145px;
    padding: 0px 0 20px 0;
  }

  .chat_list {
    padding: 8px 0px 8px;
  }

  .chat_ib h5 {
    font-size: 12px;
  }

  .chat_ib h5 span {
    font-size: 10px;
  }

  .inbox_people {
    width: 47%;
  }

  .mesgs {
    width: 50%;
    padding: 5px 0px 0 0px;
  }

  .pagination {
    margin: 0px 0px 20px;
  }

  .total-bounce-message {
    font-size: 12px;
  }
}

.dashboard-sidebar-header {
  background: linear-gradient(355deg, #e3e3e3 0, #fff 100%);
  padding: 5.5px 20px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99999;
  height: 73px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  margin: auto;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
}

.dashboard-sidebar-header img {
  width: 80%;
}

.dashboard-sidebar a {
  text-decoration: none;
}

.dashboard-sidebar-help a {
  text-decoration: underline;
  color: rgb(60, 60, 218);
}

.dashboard-sidebar-content {
  position: fixed;
  right: -300px;
  top: 50px;
  height: 100%;
  z-index: 9999 !important;
  background: #fff !important;
  transition: all 0.45s ease-out;
}

.active {
  right: 0;
  top: 86px;
}

.dashboard-sidebar-menu-item {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px 30px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.dashboard-sidebar-menu-item span {
  color: #4a4a4a;
  font-weight: 500;
  font-size: 14px;
}

.dashboard-sidebar-menu-item:hover,
.dashboard-sidebar-menu-item:focus {
  background: #f3f4f5;
}

.dashboard-sidebar-menu-item .side-icon {
  margin-right: 14px;
  color: #979797;
  font-size: 20px;
}

.dashboard-sidebar-help {
  margin-top: 270px;
  margin-bottom: 50px;
  padding: 0px 30px;
}

.help-content {
  margin-bottom: 10px;
  font-size: 14px;
}

.dashboard-sidebar-menu {
  margin-top: 95px;
}

@media all and (min-width: 768px) {
  .dashboard-sidebar {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0px;
    z-index: 99999;
    width: 20%;
    min-width: 200px;
    /* height: 100%; */
    padding-right: 1px;
    border-right: 2px solid #e5e5e5;
  }

  .dashboard-sidebar-header {
    width: 20%;
    min-width: 200px;
    position: fixed;
  }

  .dashboard-sidebar-content {
    position: static;
    /* margin-top: 68px; */
  }

  .dashboard-sidebar-header img {
    width: 100%;
  }
}

.dashboard-main-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Dashboard-main-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

@media all and (min-width: 768px) {
  .Dashboard-main-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}

.login-main-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 100px 10px 0px;
}

.login-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding: 30px 20px;
  border-radius: 8px;
  background-color: #fff;
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 24px;
  box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%);
}

.login-logo {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  margin: auto;
  cursor: pointer;
}

.login-card {
  margin-top: 20px;
}

.login-container input[type="text"],
.login-container input[type="password"] {
  display: block;
  border: none;
  border-radius: 5px;
  background: rgba(109, 124, 144, 0.1);
  width: 100%;
  margin: 16px 0;
  padding: 8px 12px;
  text-align: left;
  border: 2px solid transparent;
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.8em;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #20292f;
  outline: none;
}

.login-container input[type="text"]:hover,
.login-container input[type="password"]:hover {
  border: 2px solid #3776ff;
}

.forgot-text {
  text-align: center;
  margin-top: 20px;
  color: #6c7d90;
  font-size: 18px;
}

.login-btn {
  width: 100%;
  border: 0;
  outline: 0;
  border-radius: 9999px;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  background: linear-gradient(120deg, rgba(103, 151, 255, 0) 0%, #4d86ff 100%);
  background-color: #67b5ff;
  cursor: pointer;
}

.login-error {
  font-size: 18px;
  color: red;
  margin: 20px auto -10px;
}

@media all and (min-width: 768px) {
  .login-container {
    width: 37%;
  }
}

.contact-container {
  min-height: 90vh;
  padding: 120px 7% 40px;
  margin: 0 auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.contact-form-container {
  width: 70%;
  margin: auto;
}

.contact-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.contact-title h1 {
  color: #0000008c;
  margin-bottom: 32px;
}

.contact-container hr {
  margin: 1.6rem 0;
}

.contact-form-content {
  margin-bottom: 1rem;
}

.contact-form-content p {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
}

.contact-form-container form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 13px;
  gap: 13px;
  width: 100%;
}

.contact-form-container form .form-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

.contact-form-container form .form-group label {
  font-size: 1.2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}
.contact-form-container form .form-group input,
.contact-form-container form .form-group textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  outline: none;
}

.contact-btn {
  width: 150px;
  background: #7357eb;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  outline: none;
}

@media (max-width: 450px) {
  .contact-container {
    padding: 120px 1rem 40px;
  }
  .contact-form-container {
    width: 100%;
  }
}

.resister-main-container {
  padding: 20px 40px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #f0f5ff 37.5%, #ffffff 37.5%);
}

.resister-main-container a {
  font-weight: 600;
  color: #007bff;
}

.country-label {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.resister-checkbox {
  margin-bottom: 10px;
}

.resister-checkbox-input {
  font-size: 1rem;
  margin-right: 10px;
}

.resister-checkbox label {
  font-size: 1rem;
  color: #555;
}

.button-resistation {
  width: 100%;
  padding: 12px;
  color: #fff;
  font-size: 1rem;
  background-color: #007bff;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.resister-text-center {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 10px;
}

.resiter-package-title {
  font-size: 24px;
  margin: 20px 0px;
  text-align: center;
}

.resister-package-box {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.resister-main-container .card {
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  padding: 25px 0px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%);
}

.resister-main-container .card:hover,
.resister-main-container .card:focus {
  outline: none;
  border: 2px solid blue;
}

.resister-package-box .card-body {
  text-align: center;
  position: relative;
}

.resister-package-box .card-body h5 {
  font-size: 1.5rem;
  margin-bottom: 6px;
}

.resister-package-box sup,
.resister-package-box sub {
  font-weight: 600;
  font-size: 1rem;
  color: #333;
}

.resister-package-box span {
  font-weight: 900;
  line-height: 60px;
  font-size: 60px;
  color: #3772ff;
}

.resister-package-box sup {
  position: relative;
  top: -1.7em;
}

.resister-account-box {
  width: 100%;
}

.resister-input {
  width: 100%;
  padding: 12px;
  background: rgba(109, 124, 144, 0.1);
  color: #495057;
  margin-bottom: 15px;
  border: none;
  font-size: 15px;
  transition: all 0.1s ease-in;
}

.resister-input:hover,
.resister-input:focus {
  border: 2px solid blue;
  box-shadow: none;
}

.card-amount {
  font-size: 24px;
  color: rgb(87, 87, 241);
  font-weight: 700;
}

.resister-stripe-image {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.resister-stripe-image img {
  height: 100px;
}

.button-resistation {
  margin-top: 20px;
}

.resister-input-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 18px;
  padding-top: 10px;
}

.resister-input-button input {
  margin-right: 5px;
}

.resister-radio-first {
  margin-right: 30px;
}

.resister-package-img {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 30px;
}
.resister-package-img img {
  width: 75%;
  margin: 0 auto;
}

.card.greenborder {
  border: 2px solid green;
}

@media all and (min-width: 768px) {
  .resister-main-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: linear-gradient(to right, #f0f5ff 50%, #ffffff 50%);
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .resister-package-box {
    width: 47%;
    box-sizing: border-box;
    margin-bottom: auto;
    margin-top: auto;
  }

  .resister-account-box {
    width: 47%;
    margin-bottom: 0px;
  }

  .resister-input-group {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .resister-input-group .resister-input {
    width: 48%;
  }
}

.payment-modal-container {
  background: #fff;
  width: 95%;
  margin: 40px auto;
  border: none;
  outline: none;
  top: 100px;
}

.modal-header {
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  padding: 10px;
  color: #fff;
  font-weight: 600;
  position: relative;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  text-align: center;
}
.modal-header-signup {
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  padding: 10px;
  color: #fff;
  font-weight: 600;
  position: relative;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  text-align: center;
}
.modal-header .close {
  position: absolute;
  top: 7px;
  right: 15px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.6rem;
  cursor: pointer;
}

.modal-header-signup .close {
  position: absolute;
  top: 7px;
  right: 15px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.6rem;
  cursor: pointer;
}

.modal-body-title {
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.modal-header p {
  margin-bottom: 0 !important;
  font-size: 1.1rem;
}
.securePayment img {
  margin: 0 auto;
  /* width: 425px; */
  display: -webkit-flex;
  display: flex;
  /* height: 122px; */
  width: 80%;
  object-fit: cover;
}
.paymentFrm {
  padding: 0px 10px;
}

.paymentFrm .form-group label {
  display: block;
  margin-bottom: 8px;
}

.paymentFrm .form-group input {
  display: block;
  width: 100%;
  margin-bottom: 12px;
  padding: 8px 20px;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 10px -4px #ccc;
}

.form-control.red-error-border {
  border: none !important;
  border: 1px solid #ff6046 !important;
}

.form-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.col-xs-4 {
  width: 32%;
}

.payment-success-button {
  width: 100%;
  display: inline-block;
  padding: 12px;
  margin-top: 20px;
  font-size: 15px;
  text-align: center;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(7deg, #0752feeb 0, #46b3ff 100%);
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s linear;
}

.payment-success-button-signup {
  margin-bottom: 0px;
}

.payment-success-button:hover,
.payment-success-button:focus {
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
}

.payment-success-button.disabled:hover {
  cursor: not-allowed;
}

.payment-success-button.disabled {
  cursor: progress;
  pointer-events: all !important;
}

.payment-footer-signup {
  background: linear-gradient(7deg, #46b3ff 0, #0752feeb 100%);
  text-align: center;
  padding: 20px 0px;
  margin-top: 15px;
  position: relative;
  width: 100%;
  bottom: 0;
  color: #fff;
}

.payment {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-transform: uppercase;
  font-size: 20px;
}

.payment .lock-icon {
  width: 18px;
  height: 18px;
  margin-right: 3px;
  /* margin-bottom: 8px; */
}

.payment span {
  font-size: 24px;
  color: #fff;
  font-family: lato;
  font-weight: 600;
}

.modal-body-title-left h4 {
  font-size: 1rem;
}

.modal-body-title-right {
  font-size: 24px;
  color: #f3881f;
  font-weight: 700;
}

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 2rem 0 1rem !important;
}
.cupon-click {
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: var(--orange);
}

.cupon-validate-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.cupon-validate-content {
  width: 100% !important;
}
.cupon-validate-container input {
  width: 90% !important;
}

.cupon-validate-button {
  padding: 8px 20px;
  background-color: var(--orange);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s linear;
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
}

.cupon-validate-button:hover,
.cupon-validate-button:focus {
  letter-spacing: 1px;
}

.account-footer {
  text-align: center;
  margin-top: 20px;
  font-size: 1.1rem;
}

.account-footer span {
  cursor: pointer;
  text-decoration: underline;
}

.thanku-content {
  text-align: center;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.thanku-content h4 {
  font-size: 1.8rem;
  color: rgb(42, 197, 42);
}

.thanku-content p {
  color: rgb(253, 10, 10);
  text-transform: capitalize;
}

@media all and (min-width: 768px) {
  .payment-modal-container {
    width: 40%;
  }

  .modal-body-title-left h4 {
    font-size: 1.5rem;
  }

  .modal-body {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 4rem 1rem 2rem !important;
  }

  .modal-header {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .modal-header-signup {
    background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
    padding: 10px;
    color: #fff;
    font-weight: 600;
    position: relative;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    text-align: center;
  }

  .modal-body-title {
    display: -webkit-flex;
    display: flex;
    padding: 10px 30px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .paymentFrm {
    padding: 0px 30px;
  }

  .cupon-click {
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    color: var(--orange);
  }

  .cupon-validate-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
  }

  .cupon-validate-content {
    width: 100% !important;
  }
  .cupon-validate-container input {
    width: 90% !important;
  }

  .cupon-validate-button {
    padding: 8px 20px;
    background-color: var(--orange);
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s linear;
    margin-top: 0px;
  }

  .cupon-validate-button:hover,
  .cupon-validate-button:focus {
    letter-spacing: 1px;
  }

  .payment-success-button {
    margin-bottom: 40px;
  }

  .payment-success-button-signup {
    margin-bottom: 0px;
  }

  .payment-footer {
    width: 100%;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
  }
  .payment-footer-signup {
    background: linear-gradient(7deg, #46b3ff 0, #0752feeb 100%);
    text-align: center;
    padding: 20px 0px;
    margin-top: 15px;
    position: relative;
    width: 100%;
    bottom: 0;
    color: #fff;
  }
}

@media all and (min-width: 768px) and (max-width: 1000px) {
  .payment-modal-container {
    width: 55%;
  }
  .cupon-validate-container input {
    width: 90% !important;
  }
}

