@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

.dashboard-sidebar-header {
  background: linear-gradient(355deg, #e3e3e3 0, #fff 100%);
  padding: 5.5px 20px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99999;
  height: 73px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
}

.dashboard-sidebar-header img {
  width: 80%;
}

.dashboard-sidebar a {
  text-decoration: none;
}

.dashboard-sidebar-help a {
  text-decoration: underline;
  color: rgb(60, 60, 218);
}

.dashboard-sidebar-content {
  position: fixed;
  right: -300px;
  top: 50px;
  height: 100%;
  z-index: 9999 !important;
  background: #fff !important;
  transition: all 0.45s ease-out;
}

.active {
  right: 0;
  top: 86px;
}

.dashboard-sidebar-menu-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.dashboard-sidebar-menu-item span {
  color: #4a4a4a;
  font-weight: 500;
  font-size: 14px;
}

.dashboard-sidebar-menu-item:hover,
.dashboard-sidebar-menu-item:focus {
  background: #f3f4f5;
}

.dashboard-sidebar-menu-item .side-icon {
  margin-right: 14px;
  color: #979797;
  font-size: 20px;
}

.dashboard-sidebar-help {
  margin-top: 270px;
  margin-bottom: 50px;
  padding: 0px 30px;
}

.help-content {
  margin-bottom: 10px;
  font-size: 14px;
}

.dashboard-sidebar-menu {
  margin-top: 95px;
}

@media all and (min-width: 768px) {
  .dashboard-sidebar {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0px;
    z-index: 99999;
    width: 20%;
    min-width: 200px;
    /* height: 100%; */
    padding-right: 1px;
    border-right: 2px solid #e5e5e5;
  }

  .dashboard-sidebar-header {
    width: 20%;
    min-width: 200px;
    position: fixed;
  }

  .dashboard-sidebar-content {
    position: static;
    /* margin-top: 68px; */
  }

  .dashboard-sidebar-header img {
    width: 100%;
  }
}
