.banner {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
  background-image: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.email-heading-content {
  text-align: center;
  width: 70%;
}

.email-heading-content .email-verifier-title {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
}

.email-heading-content .email-verifier-subtitle {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  margin-top: 20px;
}

.banner-container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 47px;
  padding-top: 15px;
  background: linear-gradient(0deg, #fbfbfe, #fbfbfe);
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px #455d7314;
}

.banner-container-form.extra-padding {
  padding: 47px;
}

.banner-container-form-title-text {
  font-size: 1.6rem;
  font-weight: bold;
  color: #000;
  margin-left: 10px;
}

.banner-container-form-title {
  display: flex;
  align-items: center;
}

.banner-container-form {
  margin-top: 60px;
  width: 70%;
}

.banner-container-form form {
  width: 100%;
  display: flex;
  align-items: center;
}

.banner-container-form form input {
  width: 100%;
  padding: 10px;
  font-size: 19px;
  border-color: #adadad2e;
}
.banner-container-form form input:hover {
  border-color: #2a3fe687;
}
.banner-container-form-button {
  padding: 12px 25px;
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  color: #fff;
  font-weight: 600;
  border: 0;
  outline: 0;
  border-radius: 10px;
  margin-left: 10px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 20px;
}
.banner-container-form-button:hover {
  background: linear-gradient(9deg, #1990e6 0, #0f50e7eb 100%);
}

.banner-container-form-button.disbaled-form-button {
  cursor: not-allowed;
  opacity: 0.4;
}

.rechapcha-container {
  margin: -11px 0px 33px;
}

.or-divider {
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: capitalize;
  color: #fff;
}

.email-verifier-upload button {
  padding: 12px 25px;
  background-image: linear-gradient(
    60deg,
    #7a1ff49c 0%,
    #bb5ed9de 100%
  ) !important;
  color: #fff;
  font-weight: 600;
  border: 0;
  outline: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 28px;
}
.bulk-verify {
  color: #fbfbfe;
  cursor: pointer;
  margin-top: 37px;
  background-color: #f0f8ff2e;
  padding: 10px;
  border-radius: 4px;
}

.bulk-verify:hover {
  color: #fbfbfe;
  cursor: pointer;
  background-color: #f0f8ff53;
}

@media (max-width: 1050px) {
  .banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .banner-container-form {
    width: 100%;
  }
  .banner-container-image {
    width: 100%;
  }

  .banner-container-form {
    margin-top: 50px;
  }

  .email-heading-content {
    width: 90%;
  }
}

@media (max-width: 460px) {
  .banner-container-form-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .banner-container-form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .banner-container-form-button {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .banner-container-form {
    padding: 40px 20px;
  }

  .banner-container-form-title-text {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
    margin-left: 0px;
    margin-top: 10px;
  }

  .email-heading-content {
    text-align: center;
    width: 100%;
    margin-top: 100px;
  }

  .email-heading-content .email-verifier-subtitle {
    font-size: 24px;
  }

  .email-heading-content .email-verifier-title {
    font-size: 40px;
  }

  .email-verifier-upload button {
    padding: 12px 20px;
    font-size: 16px;
  }
}
