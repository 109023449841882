section {
  padding: 2rem 7%;
}

@media (max-width: 925px) {
  section {
    padding: 2rem;
  }
}

@media (max-width: 460px) {
  section {
    padding: 1rem;
  }
}
