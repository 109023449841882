.dashboard-main-content-box {
  padding: 155px 10px 40px;
  overflow-x: hidden;
  overflow-y: auto;
}

.projects-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.icon {
  height: 2rem;
  width: 2rem;
}

.project-card {
  width: 32%;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #666;
  /* box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%); */
  /* box-shadow: 0 8px 2px 0 rgb(103 151 255 / 11%),
    0 12px 20px 0 rgb(103 151 255 / 11%); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
  border-radius: 10px;
}

.project-card:last-child {
  border-right: none;
}

.project-num {
  font-size: 24px;
  color: #999;
  margin: 6px 0;
  font-weight: bold;
}

.current-status {
  margin-top: 30px;
  color: #999;
}

.current-status-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
}

.status-card {
  width: 100%;
  border-radius: 20px;
  /* box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%); */
  /* box-shadow: 0 8px 2px 0 rgb(103 151 255 / 11%),
    0 2px 10px 0 rgb(103 151 255 / 11%); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
  padding: 40px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.status-logo .logo {
  font-size: 50px;
  color: #666;
  margin-right: 10px;
}

.status-text h4 {
  padding: 0;
  margin: 0;
  font-size: 30px;
}

.status-text p {
  padding: 0;
  margin: 0;
  font-size: 24px;
}

@media screen and (min-width: 768px) {
  .dashboard-main-content-box {
    box-sizing: border-box;
    padding: 30px 8px;
  }

  .current-status-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .status-card {
    width: 48%;
    margin-bottom: 0px;
  }

  .current-status {
    margin-top: 100px;
    color: #999;
  }
}
