.domain-container {
  padding: 145px 10px 40px;
}

.search-icon {
  color: #999;
}

/* .domain-container h2 {
  font-size: 30px;
  margin: 0;
  margin-bottom: 30px;
}

.domain-menuhead {
  display: flex;
  align-items: center;
}

.domain-menuhead-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.domain-menuhead-item.active {
  border: none;
  outline: none;
  border: 1px solid #999;
  border-radius: 10px;
  padding: 5px 12px;
  background-color: #00838f;
  color: #fff;
}

.domain-menuhead-item-icon {
  margin-right: 2px;
}

.horizontal-line {
  border: 1px solid #999;
  width: 100%;
  background-color: #999;
  color: #999;
  margin: 10px 0px;
}

.domain-container-bodyhead {
  display: flex;
  align-items: center;
  color: #241c15;
  font-weight: 500;
  margin-top: 40px;
}

.domain-container-bodyhead h2 {
  padding: 0;
  margin: 0;
}

.list-icon.MuiSvgIcon-root {
  font-size: 40px;
}

.domain-paragraph {
  font-size: 18px;
  margin: 10px 0 20px;
} */

.domain-table-head {
  padding: 20px 0px;
  margin: 60px 0px 0px;
}

.domain-table-head-left-one {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.domain-table-head-left-one p {
  font-size: 18px;
  margin: 0px 5px;
}

.domain-table-head-inputbox {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 10px 0px 20px;
}

.domain-table-head-input {
  border: none;
  outline: none;
  padding: 5px 10px;
  width: 100%;
}

.table-head-checkbox {
  width: 20px;
  height: 20px;
}

.domain-sending-button {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  background: #00838f;
  color: #fff;
  width: 200px;
  cursor: pointer;
}

.table-head-select {
  width: 100px;
  border: none;
  outline: none;
  background: #ccc;
  padding: 10px;
  padding-left: 0px;
  border-radius: 1px;
}

.table-head-select option {
  border: none;
  outline: none;
  border-radius: 10px;
}

@media all and (min-width: 768px) {
  .domain-container {
    padding: 0px;
  }

  .domain-table-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .domain-table-head-left {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .domain-table-head-inputbox {
    margin: 0px;
  }
  .domain-table-head-input {
    width: 300px;
  }

  .sending-domain-modal-container {
    border: 1px solid #ccc;
    width: 450px;
    height: auto;
    position: absolute;
    top: 100px;
    right: 36%;
    background-color: #fff;
    padding: 20px 15px;
  }
}
