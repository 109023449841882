header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 1rem 7%;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  font-family: "Artifika", Georgia, "Times New Roman", serif;
  font-weight: bold;
  transition: 0.3s linear;
}

header .logo {
  font-size: 2rem;
  font-weight: bolder;
  color: #666;
}

header img {
  width: 16rem;
  object-fit: cover;
}

header .navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

header .navbar a {
  font-size: 1.2rem;
  margin-left: 1rem;
  color: #666;
  text-decoration: none;
}

header .navbar a:hover {
  color: var(--red);
}

#menu-bar {
  font-size: 1.6rem;
  cursor: pointer;
  color: #666;
  border: 0.1rem solid #666;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem 0;
  display: none;
}

@media (max-width: 1050px) {
  header {
    padding: 2rem;
    font-size: 55%;
  }
}

@media (max-width: 925px) {
  #menu-bar {
    display: initial;
  }

  header .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: 0.3s linear;
  }

  header .navbar.active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  header .navbar a {
    width: 100%;
    margin: 1rem;
    padding: 0.9rem;
    display: block;
    border: 0.2rem solid rgba(0, 0, 0, 0.1);
    border-left: 0.5rem solid #c756ec;
    background: #fff;
  }

  .active {
    border-bottom: 0;
  }
}

@media (max-width: 460px) {
  header {
    padding: 1rem;
  }
  header img {
    width: 14rem;
    object-fit: cover;
  }
}
