.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.wrapper {
  display: table-cell;
  vertical-align: middle;
  margin-top: 80px;
}

.arrow-steps .step {
  font-size: 20px;
  text-align: center;
  color: #000;
  margin: 0 3px;
  padding: 20px 10px 20px 30px;
  min-width: 290px;
  float: left;
  position: relative;
  background-color: #b4e7ff;
  /* background-color: #ff5050; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  width: 100%;
  cursor: pointer;
}

.arrow-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 33px solid transparent;
  border-bottom: 31px solid transparent;
  border-left: 17px solid #b4e7ff;
  /* border-left: 17px solid #ff5050; */
  z-index: 2;
  transition: border-color 0.2s ease;
}
.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}
.arrow-steps .step:first-child:before {
  border: none;
}
.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.arrow-steps .step span {
  position: relative;
}
.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
  color: #06ac77;
}
.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}
.arrow-steps .step.current {
  color: #fff;
  background-color: #ff5050;
}
.arrow-steps .step.current:after {
  border-left: 17px solid #ff5050;
}

.wrapper h4 {
  text-align: center;
  font-size: 18px;
  color: #ff5050;
}
@media (max-width: 765px) {
  .arrow-steps .step {
    min-width: 35px;
  }

  .arrow-steps .step {
    font-size: 10px;
    padding: 20px 10px 20px 24px;
    min-width: 33%;
  }

  .arrow-steps .step:after,
  .arrow-steps .step:before {
    border-top: 28px solid transparent;
    border-bottom: 24px solid transparent;
  }

  .arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -10px;
    color: #06ac77;
  }
}

@media (max-width: 375px) {
  .arrow-steps .step {
    font-size: 10px;
    padding: 20px 2px 20px 30px;
    min-width: 33%;
  }

  .arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -10px;
    color: #06ac77;
  }
}
