.billing-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 145px 10px 40px;
}

.billing-cards-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.billing-card {
  text-align: center;
  background-color: #fff;
  width: 100%;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  outline: none;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 0.3s linear;
}

.billing-card.green {
  border: 3px solid green;
}

.billing-card:hover {
  border: 2px solid blue;
}

.billing-card:first-child {
  margin-bottom: 20px;
}

.billing-card h5 {
  font-size: 24px;
  margin-bottom: 6px;
}

.billing-card span {
  font-size: 60px;
  color: #3772ff;
  line-height: 60px;
  font-weight: 900;
}

.billing-card sup sub {
  font-weight: 400;
  font-size: 0.9rem;
  color: #555;
}

.billing-card sup {
  font-size: 18px;
  vertical-align: auto;
  position: relative;
  top: -27px;
}

.billing-card sub {
  font-size: 18px;
}

.btn-grp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.btn-update button {
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  background: green;
  color: #fff;
  margin-right: 10px;
}

.btn-cancel button {
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  background: red;
  color: #fff;
}

.btn-cancel button:hover,
.btn-update button:hover {
  border: 3px solid #dbdde3;
}

.subscriptions {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.subscriptions-left {
  padding: 8px 0px;
  font-size: 18px;
}

.subscriptions-left h2 {
  margin-bottom: 12px;
}

.subscription-cancelation-btn {
  padding: 8px 20px;
  border: none;
  outline: none;
  background: red;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  text-transform: capitalize;
}

.modal-class-package {
  position: absolute;
  top: 14%;
  left: 35px;
  width: 80%;
  z-index: 1000;
  height: auto;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #fff;
  color: #999;
  position: relative;
}

.billing-section-addCredit-btn .btn {
  color: #ccc;
  border-radius: 4px;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #ccc;
  padding: 0.9rem 2rem;
  font-size: 1.8rem;
  background-size: 300%;
  transition: all 0.6s;
  border-radius: 10px;
  cursor: pointer;
}

.billing-section-addCredit-btn .btn.blue {
  color: #fff !important;
  background-image: linear-gradient(160deg, #46b3ff 50%, #0752fe 50%);
}

.billing-section-addCredit-btn .btn:hover {
  color: #fff !important;
  background-position: 100%;
  transform: translateX(0.5rem);
}

.billing-section-addCredit-btn .btn:active {
  color: #fff !important;
  transform: translate(0.5rem, 0.5rem);
}

.billing-section-addCredit-btn {
  margin: 60px 0px;
}

@media screen and (min-width: 768px) {
  .billing-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 20px;
  }

  .subscriptions {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .subscriptions-left {
    width: 70%;
    padding: 8px 0px;
    text-align: justify;
  }

  .billing-cards-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .billing-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .billing-card:first-child {
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .modal-class-package {
    top: 12%;
    left: 33%;
    width: 500px;
    padding: 30px 20px;
    position: relative;
    overflow: hidden;
  }

  .current-status-billing {
    margin: 0;
    margin-bottom: 60px;
  }
}
