.emails-container {
  padding: 145px 10px 40px;
  min-height: 80vh;
}

.table-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table-header-left {
  width: 100%;
  margin-bottom: 10px;
}

.table-header-right {
  width: 100%;
  margin-bottom: 10px;
}

.search {
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  border: 1px solid #ccc;
  border-radius: 99px;
}

.search input {
  border: none;
  outline: none;
}

.search .MuiSvgIcon-root {
  cursor: pointer;
}
/* Table part starts*/

.api-table-container {
  padding: 30px 0px 20px;
}

.api-table-container p {
  margin-bottom: 10px;
  font-size: 17px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  display: inline-block;
  overflow-x: auto;
}

#customers::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar*/
#customers {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#customers td,
#customers th {
  padding: 7px 10px;
  word-wrap: no-wrap;
  white-space: no-wrap;
  min-width: 146px;
  border: 1px solid #ccc;
  table-layout: fixed;
}

#customers th {
  background-color: #f2f2f2;
}

#customers tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

#customers .subject {
  min-width: 350px;
}

.view-email {
  width: 90px;
  padding: 7px 0px;
  cursor: pointer;
}

.date-format-box {
  min-width: 175px;
}

.clear-email-queue {
  padding: 8px 20px;
  cursor: pointer;
}

.no-email {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Table part ends */

@media all and (min-width: 768px) {
  .emails-container {
    padding: 0px;
  }
  #customers {
    width: 100%;
    /* display: table; */
    overflow-x: auto;
    scrollbar-width: none;
    margin-bottom: 20px;
  }

  .table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .table-header-left {
    width: 70%;
    margin-bottom: auto;
  }
  .table-header-right {
    width: 28%;
    margin-bottom: auto;
  }

  .search {
    width: 280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
