.footer-container {
  padding: 8px 0;
  background-color: #1f1f1f;
}

.footer-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-column.text,
.react-icon {
  color: #fff;
}

.react-icon {
  font-size: 2rem;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.2s linear;
}

.react-icon:hover,
.react-icon:focus {
  color: #089db8;
}

@media (max-width: 1050px) {
  .footer-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer-column.text {
    margin-bottom: 20px;
  }
}

@media (max-width: 450px) {
  .react-icon:first-child {
    margin-left: 0;
  }
  .footer-column.text {
    font-size: 15px;
  }
}
