.thanks-container {
  padding: 170px 10px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.check-icon.MuiSvgIcon-root {
  font-size: 140px;
  color: green;
  margin: 0 auto;
}

.thanks-check {
  display: flex;
  align-items: center;
}

.thanks-heading {
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 900;
}

.thanks-title {
  font-size: 18px;
}

.thanks-button {
  padding: 16px 30px;
  background-color: #00838f;
  color: #fff;
  border: none;
  outline: none;
  margin-top: 60px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
}

@media all and (min-width: 768px) {
  .thanks-container {
    padding: 0;
  }
}
