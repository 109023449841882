.email-create-btn {
  display: inline-block;
  padding: 12px 24px;
  color: white;
  background: linear-gradient(495deg, #46b3ff 0, #0752fe 100%);
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.failsEmail {
  background: rgb(248, 38, 38) !important;
}

.green_row td {
  background: rgba(51, 174, 51, 0.14) !important;
}

.red_row td {
  background: rgba(228, 201, 201, 0.47) !important;
}
.email-create-btn:hover,
.email-create-btn:focus {
  background: linear-gradient(495deg, #0752fe 0, #46b3ff 100%);
}

.create-project-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-class-project-name {
  position: absolute;
  top: 25%;
  left: 18px;
  width: 90%;
  z-index: 1000;
  height: auto;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #fff;
  color: #999;
}

.modal-class-add-email,
.modal-class-upload-email {
  position: absolute;
  top: 25%;
  left: 18px;
  width: 90%;
  z-index: 1000;
  height: auto;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #fff;
  color: #999;
}
.email-project-table th,
.email-project-table td {
  text-align: center !important;
}

.email-project-table td button {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* file upload */
.csv-file-upload {
  width: 100%;
  padding: 30px 0px;
  text-align: center;
  border-radius: 10px;
  border: 2px dotted #ccc;
  margin-top: 20px;
  margin-bottom: 10px;
}

.csv-upload-icon {
  font-size: 3.5rem;
  color: #668ce4;
}

.csv-drop-file h4 {
  font-size: 1.2rem;
}

.csv-or {
  color: #999;
  margin-bottom: 1rem;
}

#csv-file {
  width: 120px;
  cursor: pointer;
  outline: none !important;
  border: none !important;
  padding: 10px 20px;
  position: relative;
}

::-webkit-file-upload-button {
  color: #fff;
  background: linear-gradient(495deg, #0752fe 0, #46b3ff 100%);
  border: none;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  inset: 0;
  width: 100%;
}

/* file upload ends */

.project-details {
  min-height: 80vh;
  padding: 10px;
  margin-top: 145px;
}

.project-details table th,
.project-details table td {
  text-align: left !important;
  width: 50vw;
}

.create-project-modal-container textarea {
  border: 0;
  outline: 0;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}

.add-email-heading h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0752fe;
  margin-bottom: 10px;
}

.add-email-heading p {
  font-size: 1rem;
  color: #999;
  margin-bottom: 10px;
}

.bulk-email-row {
  justify-content: center;
  column-gap: 3rem;
}

@media all and (min-width: 768px) {
  .modal-class-project-name {
    top: 40%;
    left: 33%;
    width: 500px;
    padding: 30px 20px;
  }
  .modal-class-add-email,
  .modal-class-upload-email {
    top: 24%;
    left: 33%;
    width: 500px;
    padding: 30px 20px;
  }
  .project-details {
    margin-top: 30px;
    min-height: 80vh;
    padding: 0px;
  }
}
