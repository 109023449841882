.api-container {
  padding: 145px 10px 80px;
}

.api-item h4 {
  font-size: 20px;
  margin: 16px 0px;
}

.api-item p.red {
  padding: 10px 10px;
  padding-right: 20px;
  font-size: 15px;
  background-color: rgb(250, 248, 248);
  color: #c7254e;
  word-wrap: break-word;
}

h4.red {
  color: #c7254e;
}

.api-item a {
  width: auto;
  padding: 10px 10px;
  padding-right: 20px;
  font-size: 15px;
  color: #007c89;
  background-color: rgb(250, 248, 248);
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.api-docs-message {
  padding: 15px 10px;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  background-color: #e0f7fa;
}

.api-docs-btn button {
  background-color: #00897b;
  padding: 7px 12px;
  color: #fff;
  cursor: pointer;
  border: none;
  outline: none;
}

/* Table part */
.api-table-container p {
  padding-bottom: 20px;
}

.api-table-container {
  padding: 30px 0px 20px;
}

.api-table-container p {
  margin-bottom: 10px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  display: block;
  overflow-x: auto;
}

#customers td,
#customers th {
  padding: 0px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.generate-smtp-password {
  margin: 6px 0px 0px;
}

.generate-smtp-password button {
  width: 220px;
  padding: 8px 0px;
  background: linear-gradient(495deg, #46b3ff 0, #0752fe 100%);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
}
.generate-smtp-password button:hover {
  background: linear-gradient(495deg, #1a8cdd 0, #215be2 100%);
}

@media all and (min-width: 768px) {
  .api-container {
    padding: 0px;
    padding-bottom: 80px;
  }

  #customers {
    width: 100%;
    display: table;
  }
}
