.resister-main-container {
  padding: 20px 40px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #f0f5ff 37.5%, #ffffff 37.5%);
}

.resister-main-container a {
  font-weight: 600;
  color: #007bff;
}

.country-label {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.resister-checkbox {
  margin-bottom: 10px;
}

.resister-checkbox-input {
  font-size: 1rem;
  margin-right: 10px;
}

.resister-checkbox label {
  font-size: 1rem;
  color: #555;
}

.button-resistation {
  width: 100%;
  padding: 12px;
  color: #fff;
  font-size: 1rem;
  background-color: #007bff;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.resister-text-center {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 10px;
}

.resiter-package-title {
  font-size: 24px;
  margin: 20px 0px;
  text-align: center;
}

.resister-package-box {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.resister-main-container .card {
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  padding: 25px 0px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%);
}

.resister-main-container .card:hover,
.resister-main-container .card:focus {
  outline: none;
  border: 2px solid blue;
}

.resister-package-box .card-body {
  text-align: center;
  position: relative;
}

.resister-package-box .card-body h5 {
  font-size: 1.5rem;
  margin-bottom: 6px;
}

.resister-package-box sup,
.resister-package-box sub {
  font-weight: 600;
  font-size: 1rem;
  color: #333;
}

.resister-package-box span {
  font-weight: 900;
  line-height: 60px;
  font-size: 60px;
  color: #3772ff;
}

.resister-package-box sup {
  position: relative;
  top: -1.7em;
}

.resister-account-box {
  width: 100%;
}

.resister-input {
  width: 100%;
  padding: 12px;
  background: rgba(109, 124, 144, 0.1);
  color: #495057;
  margin-bottom: 15px;
  border: none;
  font-size: 15px;
  transition: all 0.1s ease-in;
}

.resister-input:hover,
.resister-input:focus {
  border: 2px solid blue;
  box-shadow: none;
}

.card-amount {
  font-size: 24px;
  color: rgb(87, 87, 241);
  font-weight: 700;
}

.resister-stripe-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resister-stripe-image img {
  height: 100px;
}

.button-resistation {
  margin-top: 20px;
}

.resister-input-button {
  display: flex;
  align-items: center;
  padding-bottom: 18px;
  padding-top: 10px;
}

.resister-input-button input {
  margin-right: 5px;
}

.resister-radio-first {
  margin-right: 30px;
}

.resister-package-img {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.resister-package-img img {
  width: 75%;
  margin: 0 auto;
}

.card.greenborder {
  border: 2px solid green;
}

@media all and (min-width: 768px) {
  .resister-main-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background: linear-gradient(to right, #f0f5ff 50%, #ffffff 50%);
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .resister-package-box {
    width: 47%;
    box-sizing: border-box;
    margin-bottom: auto;
    margin-top: auto;
  }

  .resister-account-box {
    width: 47%;
    margin-bottom: 0px;
  }

  .resister-input-group {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }

  .resister-input-group .resister-input {
    width: 48%;
  }
}
