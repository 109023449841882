.verify-section {
  min-height: 80vh;
  background-image: linear-gradient(60deg, #8834fd 0%, #c756ec 100%) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify-section-container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px;
}

.verify-section-content {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.verify-section-content-title {
  font-size: 2.6rem;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  line-height: 1.4em;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}

.verify-section-content-text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #fff;
}

.verify-section-content button {
  width: 180px;
  padding: 20px 30px;
  border-radius: 6px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  outline: 0;
  border: 2px solid #fff;
  color: #fff;
  margin-top: 30px;
  cursor: pointer;
  transition: 0.2s linear;
}

.verify-section-content button:hover,
.verify-section-content button:focus {
  background-color: hsla(0, 0%, 100%, 0.2);
  border: 0;
  outline: 0;
}

@media (max-width: 1050px) {
  .verify-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;
  }

  .verify-section-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .verify-section {
    min-height: 60vh;
  }

  .verify-section-content-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 460px) {
  .verify-section {
    min-height: 70vh;
  }
  .verify-section-container {
    width: 100%;
  }

  .verify-section-content-title {
    font-size: 1.7rem;
    line-height: 1.2em;
  }

  .verify-section-content button {
    padding: 16px 30px;
  }
}
