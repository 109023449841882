.payment-modal-container {
  background: #fff;
  width: 90%;
  margin: 80px auto;
  border: none;
  outline: none;
}

hr {
  margin: 4px 0px;
}

.modal-body-title {
  display: flex;
  flex-direction: row;
  padding: 35px 12px 5px;
  justify-content: space-between;
}

.paymentFrm {
  padding: 0px 10px;
}

.paymentFrm .form-group label {
  display: block;
  margin-bottom: 4px;
}

.paymentFrm .form-group input {
  display: block;
  width: 100%;
  margin-bottom: 4px;
  padding: 4px 20px;
  border: none;
  outline: none;
  border: 1px solid #ccc;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-card-used {
  padding: 0px 25px;
  text-align: center;
}

.modal-card-used h4 {
  margin: 0;
  margin-bottom: 5px;
}

.modal-card-used input {
  margin: 5px 0;
  margin-right: 5px;
}

.payment-success-button {
  width: 100%;
  display: inline-block;
  padding: 8px;
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  /* background: Linear-gradient(
    to bottom,
    rgba(248, 153, 57, 1) 0,
    rgba(238, 122, 6, 1) 100%
  ); */
  background: linear-gradient(350deg, #46b3ff 0, #0752fe 100%);
  cursor: pointer;
}

.payment-success-button.disabled {
  cursor: not-allowed;
}

.payment-footer {
  background: linear-gradient(5deg, #46b3ff 0, #0752feeb 100%);
  color: #fff;
  text-align: center;
  padding: 10px 0px;
  margin-top: 15px;
}

.form-group-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.col-xs-4 {
  width: 32%;
}

.addCreditModalError {
  text-align: center;
  font-size: 14px;
  color: red;
}

@media all and (min-width: 768px) {
  .payment-modal-container {
    background: #fff;
    width: 90%;
    margin: 80px auto;
    border: none;
    outline: none;
  }

  .modal-body-title {
    padding: 10px 30px;
  }

  .paymentFrm {
    padding: 0px 30px;
  }

  .paymentFrm .form-group label {
    display: block;
    margin-bottom: 8px;
  }

  .paymentFrm .form-group input {
    display: block;
    width: 100%;
    margin-bottom: 12px;
    padding: 8px 20px;
    border: none;
    outline: none;
    border: 1px solid #ccc;
  }

  .form-group-container {
    display: flex;
    justify-content: space-between;
  }

  .payment-modal-container .col-xs-4 {
    width: 32%;
  }

  .payment-success-button {
    padding: 12px;
    margin-top: 20px;
  }

  .payment-footer {
    padding: 20px 0px;
  }

  .modal-card-used input {
    margin: 10px 0;
    margin-right: 10px;
  }

  hr {
    margin: 10px 0px;
  }

  .payment-modal-container {
    width: 35%;
  }
}
