.contact-container {
  min-height: 90vh;
  padding: 120px 7% 40px;
  margin: 0 auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.contact-form-container {
  width: 70%;
  margin: auto;
}

.contact-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.contact-title h1 {
  color: #0000008c;
  margin-bottom: 32px;
}

.contact-container hr {
  margin: 1.6rem 0;
}

.contact-form-content {
  margin-bottom: 1rem;
}

.contact-form-content p {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;
}

.contact-form-container form .form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.contact-form-container form .form-group label {
  font-size: 1.2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}
.contact-form-container form .form-group input,
.contact-form-container form .form-group textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  outline: none;
}

.contact-btn {
  width: 150px;
  background: #7357eb;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  outline: none;
}

@media (max-width: 450px) {
  .contact-container {
    padding: 120px 1rem 40px;
  }
  .contact-form-container {
    width: 100%;
  }
}
