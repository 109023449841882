@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #241c15;
  /* box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%);
  background-image: linear-gradient(
    rgba(103 151 255 / 11%),
    rgba(103 151 255 / 11%)
  ); */
}

a:hover,
a:focus {
  text-decoration: none !important;
  color: #24151c !important;
}

/* scroll problem starts */

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: absolute;
  z-index: 999999;
  top: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.51) !important;
  overflow-y: auto;
}

/* scroll problem ends */

/* background-color: #00838f; input:: background: rgba(109,124,144,.1) */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(270deg, #82aac7 0, #98b0e6 100%);
}
