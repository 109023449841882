.validation-page-container {
  min-height: 90vh;
  padding: 120px 7% 20px;
  margin: 0 auto;
}

.validation-page-adds {
  border: 1px solid rgb(248, 248, 248);
}

.validation-page-info-container {
  font-size: 1.1rem;
  margin: 0rem 0 2rem;
  box-shadow: 0 1px 0px -4px rgb(0 0 0 / 10%), 0 2px 4px 2px rgb(0 0 0 / 10%);
  padding: 2.5rem 1rem;
}

.validation-page-info-title {
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
  font-weight: bold;
}

.validation-page-info-content h4 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
}

.validation-page-info-content div {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  word-wrap: break-word;
}
.validation-page-info-content div .valid {
  color: green;
}

.validation-page-info-content div .invalid {
  color: red;
}

.validation-page-info-footer {
  margin-top: 1.4rem;
}

.validation-page-info-footer h4 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
}

.validation-page-info-footer button {
  padding: 0.8rem 2rem;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  border: none;
  outline: none;
  background: #7357eb;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 0.5rem;
  margin-top: 0.9rem;
}

.error-message-validateion {
  width: 100%;
  text-align: center;
  background-color: rgba(243, 14, 64, 0.6);
  /* color: #fff; */
  padding: 0.8rem;
  margin-bottom: 1.4rem;
  font-weight: 600;
}
@media (max-width: 450px) {
  .validation-page-container {
    padding: 120px 1rem 20px;
  }

  .validation-page-info-title {
    font-size: 1.5rem;
  }

  .validation-page-info-footer h4 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
  }

  .validation-page-info-content h4 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
  }

  .validation-page-info-content div {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: flex-start;
  }

  .validation-page-info-content div .icon {
    font-size: 0.2rem;
  }
}
