.plans a {
  text-decoration: none;
  color: #999;
}

.user-popup {
  display: flex;
  align-items: center;
}

.dashboard-main-nav {
  /* background: #7d63ec; */
  background: linear-gradient(350deg, #46b3ff 0, #0752fe 100%);
  color: #fff;
  padding: 23.5px 5px 12.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 62px;
  z-index: 9999;
  margin-bottom: 100px;
}

.add-money a {
  text-decoration: none;
  color: #f6533f;
  display: inline-block;
  width: 90px;
  background: #fff;
  border-radius: var(--border-radius);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding: 5px 10px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  position: relative;
  border-radius: 5px;
  border: 3px solid transparent;
}

.add-money a:hover {
  box-shadow: none;
  color: #f54a4a;
  border: 3px solid #dbdde3;
}

.dashboard-main-nav-left,
.dashboard-main-nav-right {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-icons {
  margin-left: 2px;
  margin-right: 15px;
}
.nav-icons:hover {
  border-radius: 5px;
  background-color: #0000005e;
  transition: ease-in;
}

.dashboard-main-nav span {
  font-size: 14px;
}

.dashboard-main-nav-right img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 0px;
}

.popup-box {
  width: 120px;
  font-size: 0.9rem;
  background: #fff;
  text-align: left;
  border-radius: 6px;
  border: 1px solid rgba(182, 182, 182, 0.1);
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 13%);
}

.popup-box p {
  padding: 20px 20px;
  color: #000;
  transition: all 0.35s ease-in;
}

.popup-box p:hover,
.popup-box p:focus {
  background: #f8f9fa;
}

.popup-box.social-box {
  position: absolute;
  top: -145px;
  left: 10px;
  display: none;
  transition: all 750ms linear;
}

.popup-box.social-box.social-active {
  top: 75px;
  left: 10px;
  display: block;
}

.popup-box.user-box {
  width: 300px;
  position: absolute;
  top: -190px;
  right: 40px;
  display: none;
  transition: all 750ms linear;
}

.popup-box.user-box.user-active {
  display: block;
  top: 75px;
  right: 40px;
}

.notification-bell {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notification-num {
  height: 15px;
  width: 15px;
  color: #fff;
  background-color: green;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 5px;
}

.bell-icon {
  color: #fff;
}

/* user box */

.user-box p {
  padding: 0;
  font-size: 1rem;
}

.user-box p,
button,
h4,
.line {
  margin-bottom: 10px;
}

.user-box h4 {
  color: rgb(27, 27, 27);
}

.line {
  width: 100%;
  height: 3px;
  background: #d6efe1;
}

.logout-btn {
  width: 100%;
  padding: 5px 0;
}

.btn {
  padding: 5px 10px;
  border: 0;
  outline: 0;
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 9999px;
}

.btn-monthly {
  background-color: #8cd622;
  margin-right: 6px;
}

.btn-yearly {
  background-color: #21c6c3;
}

.btn-change {
  background-color: #788665;
}

.plans {
  color: #999;
  font-size: 1rem;
  margin-bottom: 20px;
}

.plans .icons {
  font-size: 1.3rem;
  margin-right: 5px;
}

.plans .plan {
  display: flex;
  align-items: center;
}

.plans .plan p {
  margin-top: 6px;
}

.user-box {
  padding: 20px 10px;
}

.credit-box {
  padding: 5px 0px;
  text-transform: capitalize;
}

.email-ammount {
  display: flex;
  align-items: center;
  align-self: center;
  padding: 5px 8px;
  margin-right: 10px;
}

.email-added {
  border-left: 2px solid #999;
  padding-left: 10px;
  margin-left: 10px;
}

.message-box {
  color: #999;
  display: flex;
  align-items: center;
  padding: 20px 18px;
}

.message-box p {
  color: #000;
  padding: 0;
  margin: 0;
  margin-left: 4px;
}

.email-icon {
  margin-right: 4px;
}

.modal-class {
  position: absolute;
  top: 140px;
  left: 20%;
  z-index: 1000;
  width: 200px;
  height: auto;
  padding: 30px 20px;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #fff;
  color: #999;
  text-align: center;
}

.red-notification {
  background-color: red;
}

@media all and (min-width: 768px) {
  .dashboard-main-nav {
    padding: 13.5px 5px;
    width: 80%;
    top: 0px;
    justify-content: space-between;
  }

  .mobile-menu {
    display: none;
  }

  .dashboard-main-nav-right img {
    display: block;
  }

  .popup-box.social-box {
    top: -85px;
    left: 10px;
  }

  .popup-box.social-box.social-active {
    top: 75px;
    left: 10px;
  }

  .popup-box.user-box {
    top: -90px;
    right: 40px;
  }

  .popup-box.user-box.user-active {
    top: 75px;
    right: 40px;
  }

  .dashboard-main-nav {
    padding: 13.5px 30px;
  }

  .add-money a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 120px;
    height: 36px;
    line-height: 30px;
    font-size: 12px;
  }

  .email-ammount {
    display: flex;
    align-items: center;
    align-self: center;
    padding: 5px 15px;
    margin-right: 20px;
  }

  .modal-class {
    top: 80px;
    left: 70%;
  }

  .dashboard-main-nav-right img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-left: 20px;
  }
}
