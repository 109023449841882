.faqs-container {
  padding: 170px 10px 40px;
}

.faqs-heading {
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
}

.faqs-container hr {
  margin-bottom: 20px;
}

.faqs-item {
  margin-bottom: 20px;
}

.faqs-item h4 {
  font-size: 28px;
}

@media all and (min-width: 768px) {
  .faqs-container {
    padding: 0px;
  }
}
